import { EditMachineRendererComponent } from '@tab-container/machine-overview/table-config/edit-machine-renderer/edit-machine-renderer.component';
import { MachineOverviewModel, Permission } from '@shared/models';
import { ColumnRendererComponent } from '@gea/digital-ui-lib';

export const EDIT_MACHINE_ACTION = 'edit-machine';

export function machineColumnDefinitionsConfig(permission: Permission) {
  const first = [
    {
      displayName: 'MACHINE_OVERVIEW.TABLE_HEADERS.MACHINE_NAME',
      key: 'machines.machineName',
      sortable: true,
      width: 500,
      renderer: {
        component: EditMachineRendererComponent as ColumnRendererComponent<MachineOverviewModel>,
      },
    },
  ];
  const second = [
    {
      displayName: 'MACHINE_OVERVIEW.TABLE_HEADERS.OMNI_ID',
      key: 'machine.omniId',
      sortable: false,
    },
  ];

  const last = [
    {
      displayName: 'MACHINE_OVERVIEW.TABLE_HEADERS.EQUIPMENT_TYPE',
      key: 'machine.equipmentType',
      sortable: false,
    },
    {
      displayName: 'MACHINE_OVERVIEW.TABLE_HEADERS.SHOUT_DOWNS',
      key: 'shutdowns',
      sortable: false,
    },
    {
      displayName: 'MACHINE_OVERVIEW.TABLE_HEADERS.WARNINGS',
      key: 'warnings',
      sortable: false,
    },
  ];

  if (permission == 'admin') {
    return [...first, ...second, ...last];
  }

  return [...first, ...last];
}
