<gea-hrt-recommendation-item
  *ngFor="let item of list; let i = index; trackBy: trackByRecommendationId"
  [recommendation]="item"
  [permission]="permission"
  [index]="i"
  [isEditable]="isEditable"
  (openImagesDialog)="openImagesDialog($event)"
  (openDeleteRecommendationDialog)="openDeleteRecommendationDialog($event)"
  (actionClicked)="actionClicked.emit($event)"
  (openEditRecommendationDialog)="openEditRecommendationDialog($event)"
  (validateRecommendation)="validateRecommendation($event)"
  (setRecommendationUseful)="setRecommendationUseful($event)" />
