import { CanActivateFn, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';
import { inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { Permission } from '@shared/models/permission.model';
import { OrganizationsState } from '@shared/state';

export const PermissionsGuard: CanActivateFn = ():
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree => {
  return inject(Store)
    .select(OrganizationsState.permission)
    .pipe(map((permission: Permission | undefined) => permission === 'admin'));
};
