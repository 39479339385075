import { Router } from '@angular/router';
import { Component, EventEmitter, Input, Output, ChangeDetectionStrategy } from '@angular/core';

import { Observable } from 'rxjs';
import { ButtonV2Module, IconModule } from '@gea/digital-ui-lib';
import { Select, Store } from '@ngxs/store';

import { Organization } from '@shared/models';
import { OrganizationsState, SetSelectedOrganization } from '@shared/state';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'gea-hrt-co-action-cell',
  standalone: true,
  imports: [CommonModule, TranslateModule, ButtonV2Module, MatIconModule, IconModule],
  templateUrl: './action-cell.component.html',
  styleUrls: ['./action-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionCellComponent {
  @Input() organization?: Organization;
  @Output() organizationSelected = new EventEmitter<void>();
  @Select(OrganizationsState.selectedId) organizationId$?: Observable<number>;

  constructor(
    readonly router: Router,
    readonly store: Store
  ) {}

  selectOrganization(organization?: Organization) {
    if (organization) {
      this.store.dispatch(new SetSelectedOrganization(organization));
      void this.router.navigate([`fault-analysis/${organization.id}`]);
      this.organizationSelected.emit();
    }
  }
}
