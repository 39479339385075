import { Injectable } from '@angular/core';
import { ApiService, ApiServiceInterface, InfoResponse, MonitoringService } from '@gea/digital-ui-lib';
import { Observable } from 'rxjs';
import { User } from '@shared/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserService implements ApiServiceInterface {
  constructor(
    readonly api: ApiService,
    readonly monitoringService: MonitoringService
  ) {
    this.monitoringService.sendTrackEvent(this.constructor.name, 'constructor');
  }
  url = 'geaid/v1/users';
  apiVersion?: string;

  getUser(userId: number | string): Observable<User> {
    return this.api.get<User>(`${this.url}/${userId}`);
  }
  getInfo(): Observable<InfoResponse> {
    return this.api.get<InfoResponse>(this.url);
  }

  setApiVersion(apiVersion?: string): ApiServiceInterface {
    this.apiVersion = apiVersion ?? this.apiVersion;
    return this;
  }

  setUrl(url?: string): ApiServiceInterface {
    this.url = url ?? this.url;
    return this;
  }
}
