import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SystemHealthCheck } from '@app/shared/models';

@Component({
  selector: 'gea-hrt-health-check-dialog',
  templateUrl: './health-check-dialog.component.html',
  styleUrl: './health-check-dialog.component.scss',
})
export class HealthCheckDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<HealthCheckDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SystemHealthCheck
  ) {}

  closeDialog() {
    this.dialogRef.close();
  }

  computedClass() {
    const className = {
      0: 'healthy',
      1: 'warning',
      2: 'error',
      '-1': 'info',
    }[this.data.status];
    return `${className}`;
  }

  get metadata(): [string, number][] {
    // @ts-expect-error Type '[string, string | number][]' is not assignable to type '[string, number][]'.
    return Object.entries(this.data.healthStatusMetadata);
  }
}
