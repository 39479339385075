import { Component, OnDestroy, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { combineLatest, Observable, of, Subject, tap } from 'rxjs';
import { map, switchMap, takeUntil, skipWhile, first } from 'rxjs/operators';
import { Store, Select } from '@ngxs/store';
import { ActivatedRoute, Params, Router } from '@angular/router';

import {
  MeasurementSerieDto,
  RecommendationDto,
  RecommendationFaultStatus,
  ProblemResolveDto,
  MachineFaultDto,
} from '@shared/models';
import {
  ClearRecommendations,
  ClearSelectedFault,
  FaultsState,
  FetchFaultCodes,
  FetchRecommendations,
  FetchRelatedOperatingData,
  MachinesState,
  OrganizationsState,
  RecommendationsState,
  RelatedOperatingDataState,
  SetRecommendationStatus,
  SetSelectedFault,
  SetSelectedMachines,
  SolveProblem,
} from '@shared/state';
import { SelectOption } from '@gea/digital-ui-lib';
import { Permission } from '@shared/models/permission.model';
import { RecommendationAction } from './recommendations';

@Component({
  selector: 'gea-hrt-troubleshooting',
  templateUrl: './troubleshooting.component.html',
  styleUrls: ['./troubleshooting.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TroubleshootingComponent implements OnInit, OnDestroy {
  FaultStatus = RecommendationFaultStatus;

  ngDestroyed$ = new Subject<void>();

  @Select(RecommendationsState.recommendationText) recommendationText$?: Observable<string>;
  @Select(RecommendationsState.approvedRecommendations) private readonly _recommendations$!: Observable<RecommendationDto[]>;
  @Select(RecommendationsState.recommendationFaultStatus) recommendationFaultStatus$?: Observable<RecommendationFaultStatus>;
  @Select(RecommendationsState.problemSolvedSuccess) problemSolvedSuccess$!: Observable<boolean>;
  @Select(RecommendationsState.selectedFault) selectedFault$!: Observable<number>;

  @Select(MachinesState.machines) machineOptions$!: Observable<SelectOption[]>;
  @Select(MachinesState.selectedMachine) selectedMachine$!: Observable<number>;

  @Select(FaultsState.faultCodes) faultOptions$!: Observable<SelectOption[]>;

  @Select(OrganizationsState.permission) _permissions$!: Observable<Permission>;
  @Select(RelatedOperatingDataState.measurements) measurements$!: Observable<MeasurementSerieDto[]>;

  dateHour = 28;

  faultOptions: SelectOption[] = [];

  constructor(
    readonly store: Store,
    readonly router: Router,
    readonly route: ActivatedRoute
  ) {}

  get selectedMachine(): number | undefined {
    return this.store.selectSnapshot(MachinesState.selectedMachine);
  }

  get selectedFault(): number | undefined {
    return this.store.selectSnapshot(RecommendationsState.selectedFault);
  }

  get annunciations(): MachineFaultDto[] {
    return this.store.selectSnapshot(FaultsState.annunciations);
  }

  get recommendations$(): Observable<RecommendationDto[]> {
    return this._permissions$.pipe(
      switchMap((permission) => {
        if (permission === 'admin') {
          return this._recommendations$;
        } else if (['executive_board', 'technician'].includes(permission)) {
          return of([]);
        } else {
          return this.filterFirstThreeRecommendations$();
        }
      })
    );
  }

  ngOnInit() {
    this.subscribeToStreams();
    this.subscribeToProblemSolvedSuccess();
  }

  ngOnDestroy() {
    this.clearRecommendationsAndFault();
    this.ngDestroyed$.next();
    this.ngDestroyed$.complete();
  }

  private subscribeToStreams() {
    const machinesReady$ = this.machineOptions$.pipe(
      skipWhile((options) => options.length < 1),
      first(),
      tap((machines) => {
        this.fetchFaultCodesIfMissing(machines);
      })
    );
    const faultsReady$ = this.faultOptions$.pipe(
      skipWhile((options) => options.length < 1),
      first()
    );

    combineLatest([this.route.queryParams.pipe(first()), machinesReady$, faultsReady$])
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe(([params, machines, faults]) => {
        this.handleQueryParams(params, machines, faults);
      });
  }

  private fetchFaultCodesIfMissing(machines: SelectOption<number>[]) {
    if (!this.store.selectSnapshot(FaultsState.faultCodes).length) {
      this.store.dispatch(new FetchFaultCodes(machines.map(({ value }) => value)));
    }
  }

  private handleQueryParams(params: Params, machines: SelectOption[], faults: SelectOption[]) {
    let machine = parseInt(params['machine'], 10);
    let faultCode = parseInt(params['faultCode'], 10);

    this.faultOptions = this.getPendingFaults(faults);

    if (isNaN(machine)) {
      machine = this.selectedMachine ?? machines[0].value;
    }
    if (isNaN(faultCode)) {
      faultCode = this.selectedFault ?? this.faultOptions[0]?.value;
    }

    this.setSelectedMachine(machine);
    this.setSelectedFaultCode(faultCode);
  }

  private clearRecommendationsAndFault() {
    this.store.dispatch(new ClearRecommendations());
    this.store.dispatch(new ClearSelectedFault());
  }

  private filterFirstThreeRecommendations$(): Observable<RecommendationDto[]> {
    return this._recommendations$.pipe(
      map((recommendations) => (recommendations.length > 3 ? recommendations.slice(0, 3) : recommendations))
    );
  }

  private getPendingFaults(faultOptions: SelectOption[]): SelectOption[] {
    return faultOptions.filter((option) =>
      this.annunciations.find((fault) => fault.faultCode === option.value && fault.countPending > 0)
    );
  }

  subscribeToProblemSolvedSuccess() {
    this.problemSolvedSuccess$.pipe(takeUntil(this.ngDestroyed$)).subscribe((success: boolean) => {
      if (success) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.router.navigate(['fault-analysis', this.store.selectSnapshot(OrganizationsState.selected)?.organization?.id]);
      }
    });
  }

  setSelectedMachine(machine: number) {
    this.setQueryParams({ machine });
    this.store.dispatch(new SetSelectedMachines([machine]));
    this.store.dispatch(new FetchFaultCodes([machine]));
    this.store.dispatch(new ClearSelectedFault());

    const { selectedMachine, dateHour } = this;

    if (selectedMachine && dateHour) {
      this.store.dispatch(new FetchRelatedOperatingData(selectedMachine, dateHour));
    }
  }

  setSelectedFaultCode(faultCode: number) {
    this.setQueryParams({ faultCode });
    this.store.dispatch(new SetSelectedFault(faultCode));
    this.fetchRecommendations();
  }

  setDateRange(dateHour: number | null) {
    if (dateHour === null) {
      return;
    }

    this.dateHour = dateHour;

    const { selectedMachine } = this;

    if (selectedMachine) {
      this.store.dispatch(new FetchRelatedOperatingData(selectedMachine, dateHour));
    }
  }

  setQueryParams(queryParams: { machine?: number; faultCode?: number }) {
    this.router.navigate([], { queryParams, queryParamsHandling: 'merge' }).catch(() => {
      console.warn('query param could not be set');
    });
  }

  fetchRecommendations() {
    const { dateHour, selectedFault, selectedMachine } = this;

    this.store.dispatch(new ClearRecommendations());

    if (!selectedFault || !selectedMachine) {
      return;
    }

    this.store.dispatch(new FetchRecommendations(selectedMachine, selectedFault));
    this.store.dispatch(new FetchRelatedOperatingData(selectedMachine, dateHour));
  }

  handleRecommendationAction({ recommendationId, statusToSet }: RecommendationAction) {
    const permission = this.store.selectSnapshot(OrganizationsState.permission);
    const { selectedFault, selectedMachine } = this;

    if (!selectedMachine || !selectedFault) {
      return;
    }

    if (permission === 'customer' || permission === 'executive_board') {
      window.alert('You do not have permission to handle recommendation actions.');
      return;
    }

    this.store.dispatch(new SetRecommendationStatus(selectedMachine, selectedFault, recommendationId, statusToSet));
  }

  dispatchProblemSolved(payload?: ProblemResolveDto) {
    const { selectedFault, selectedMachine } = this;

    if (!selectedMachine || !selectedFault) {
      return;
    }

    const { selectedRecommendations, images } = payload || {};
    this.store.dispatch(
      new SolveProblem({
        machine: selectedMachine,
        faultCode: selectedFault,
        additionalNote: payload?.additionalNote,
        selectedRecommendations,
        images,
      })
    );
  }
}
