import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ApiErrorResponse, ErrorHandlerV2Service } from '@gea/digital-ui-lib';
import { EMPTY, catchError, first, of, tap } from 'rxjs';

import { FetchFaultAnalysis } from '@shared/state/faults';
import { ProblemSolvingApiService } from '@shared/services/problem-solving-api.service';
import { RecommendationsApiService } from '@shared/services';
import {
  ClearRecommendations,
  ClearSelectedFault,
  CreateRecommendation,
  DeleteRecommendation,
  FetchRecommendations,
  FetchRecommendationsByFault,
  ReorderRecommendations,
  SetRecommendationStatus,
  SetSelectedFault,
  SolveProblem,
  SolveProblemSuccessful,
  UpdateRecommendation,
  ValidateRecommendation,
} from './recommendations.action';
import { RecommendationDto, RecommendationFaultStatus, RecommendationsStateModel, TroubleshootingDto } from '@shared/models';

@State<RecommendationsStateModel>({
  name: 'recommendations',
  defaults: {
    recommendationText: '',
    recommendations: [],
    approvedRecommendations: [],
    status: undefined,
    problemSolvedSuccess: false,
  },
})
@Injectable()
export class RecommendationsState {
  constructor(
    readonly recommendationsApi: RecommendationsApiService,
    readonly problemSolvingApi: ProblemSolvingApiService,
    readonly errorHandler: ErrorHandlerV2Service
  ) {}

  @Selector()
  static recommendationText(state: RecommendationsStateModel): string {
    return state.recommendationText;
  }

  @Selector()
  static recommendations(state: RecommendationsStateModel): RecommendationDto[] {
    return state.recommendations;
  }

  @Selector()
  static approvedRecommendations(state: RecommendationsStateModel): RecommendationDto[] {
    return state.approvedRecommendations;
  }

  @Selector()
  static recommendationFaultStatus(state: RecommendationsStateModel): RecommendationFaultStatus | undefined {
    return state.status;
  }

  @Selector()
  static selectedFault(state: RecommendationsStateModel): number | undefined {
    return state.selected;
  }

  @Selector()
  static problemSolvedSuccess(state: RecommendationsStateModel): RecommendationsStateModel['problemSolvedSuccess'] {
    return state.problemSolvedSuccess;
  }

  @Action(FetchRecommendations)
  fetchRecommendations({ patchState }: StateContext<RecommendationsStateModel>, action: FetchRecommendations) {
    return this.recommendationsApi.fetchRecommendations(action.machine, action.faultCode).pipe(
      catchError((error: ApiErrorResponse) => {
        if (error?.headers) {
          this.errorHandler.handleError(error);
        }
        return of({
          recommendationText: 'n.a.',
          recommendations: [],
          status: RecommendationFaultStatus.PENDING,
        } as TroubleshootingDto);
      }),
      first(),
      tap(({ recommendationText, recommendations, status }) => {
        patchState({
          recommendationText,
          recommendations,
          status,
        });
      })
    );
  }

  @Action(FetchRecommendations)
  fetchApprovedRecommendation({ patchState }: StateContext<RecommendationsStateModel>, action: FetchRecommendations) {
    return this.recommendationsApi.fetchApprovedRecommendations(action.machine, action.faultCode).pipe(
      catchError((error: ApiErrorResponse) => {
        if (error?.headers) {
          this.errorHandler.handleError(error);
        }
        return of({
          recommendationText: 'n.a.',
          recommendations: [],
          status: RecommendationFaultStatus.PENDING,
        } as TroubleshootingDto);
      }),
      first(),
      tap(({ recommendations }) => {
        patchState({ approvedRecommendations: recommendations });
      })
    );
  }

  @Action(FetchRecommendationsByFault)
  fetchRecommendationsByFaultCode({ patchState }: StateContext<RecommendationsStateModel>, action: FetchRecommendationsByFault) {
    return this.recommendationsApi.fetchRecommendationsByFaultCode(action.faultCode).pipe(
      catchError((error: ApiErrorResponse) => {
        if (error?.headers) {
          this.errorHandler.handleError(error);
        }
        return of([] as RecommendationDto[]);
      }),
      first(),
      tap((recommendations) => {
        patchState({
          recommendations,
        });
      })
    );
  }

  @Action(SetSelectedFault)
  setSelectedFault({ patchState }: StateContext<RecommendationsStateModel>, { faultCode }: SetSelectedFault) {
    patchState({
      selected: faultCode,
    });
  }

  @Action(ClearSelectedFault)
  clearSelectedFault({ patchState }: StateContext<RecommendationsStateModel>) {
    patchState({
      selected: undefined,
    });
  }

  @Action(ClearRecommendations)
  clearRecommendations({ patchState }: StateContext<RecommendationsStateModel>) {
    return of(
      patchState({
        recommendationText: '',
        recommendations: [],
      })
    );
  }

  @Action(ReorderRecommendations)
  reorderRecommendations({ patchState }: StateContext<RecommendationsStateModel>, action: ReorderRecommendations) {
    return this.recommendationsApi.updateRecommendationsOrder(action.recommendations).pipe(
      catchError((error: ApiErrorResponse) => this.handleError(error)),
      tap(() => {
        patchState({
          recommendations: action.recommendations,
        });
      })
    );
  }

  @Action(SetRecommendationStatus)
  setRecommendationStatus({ dispatch }: StateContext<RecommendationsStateModel>, action: SetRecommendationStatus) {
    return this.recommendationsApi
      .setRecommendationStatus(action.machine, action.faultCode, action.recommendationId, action.status)
      .pipe(
        catchError((error: ApiErrorResponse) => this.handleError(error)),
        tap(() => {
          dispatch(new FetchRecommendations(action.machine, action.faultCode));
        })
      );
  }

  @Action(UpdateRecommendation)
  updateRecommendation(
    { patchState, getState }: StateContext<RecommendationsStateModel>,
    { recommendation }: UpdateRecommendation
  ) {
    return this.recommendationsApi.updateRecommendation(recommendation).pipe(
      catchError((error: ApiErrorResponse) => this.handleError(error)),
      tap(() => {
        const currentState = getState();
        const recommendations = currentState.recommendations.map((r) => {
          return r.id === recommendation.id
            ? {
                ...r,
                ...recommendation,
              }
            : r;
        });

        patchState({
          ...currentState,
          recommendations,
        });
      })
    );
  }

  @Action(ValidateRecommendation)
  validateRecommendation(
    { patchState, getState }: StateContext<RecommendationsStateModel>,
    { recommendation }: ValidateRecommendation
  ) {
    return this.recommendationsApi.validateRecommendation(recommendation).pipe(
      catchError((error: ApiErrorResponse) => this.handleError(error)),
      tap(() => {
        const currentState = getState();
        const recommendations = currentState.recommendations.map((r) => {
          return r.id === recommendation.id
            ? {
                ...r,
                ...recommendation,
              }
            : r;
        });

        patchState({
          ...currentState,
          recommendations,
        });
      })
    );
  }

  @Action(DeleteRecommendation)
  deleteRecommendation(
    { getState, setState }: StateContext<RecommendationsStateModel>,
    { recommendationID }: DeleteRecommendation
  ) {
    return this.recommendationsApi.deleteRecommendation(recommendationID).pipe(
      catchError((error: ApiErrorResponse) => this.handleError(error)),
      tap(() => {
        const currentState = getState();
        const recommendations = [...currentState.recommendations];
        const index = recommendations.findIndex(({ id }) => id === recommendationID);

        recommendations.splice(index, 1);

        setState({
          ...currentState,
          recommendations,
        });
      })
    );
  }

  @Action(SolveProblem)
  solveProblem(
    { dispatch }: StateContext<RecommendationsStateModel>,
    { props: { faultCode, machine, selectedRecommendations, additionalNote, images = [] } }: SolveProblem
  ) {
    return this.problemSolvingApi.solveProblem({ machine, faultCode, selectedRecommendations, additionalNote, images }).pipe(
      catchError((error: ApiErrorResponse) => this.handleError(error)),
      tap(() => {
        dispatch(new SolveProblemSuccessful());
        dispatch(new FetchFaultAnalysis([], '', [], []));
      })
    );
  }

  @Action(SolveProblemSuccessful)
  solveProblemSuccessful({ patchState }: StateContext<RecommendationsStateModel>) {
    setTimeout(() => patchState({ problemSolvedSuccess: false }));
    return of(
      patchState({
        problemSolvedSuccess: true,
      })
    );
  }

  @Action(CreateRecommendation)
  createRecommendation(
    { patchState, getState }: StateContext<RecommendationsStateModel>,
    { faultCode, actionName, images }: CreateRecommendation
  ) {
    return this.recommendationsApi.createRecommendation(faultCode, actionName, images).pipe(
      catchError((error: ApiErrorResponse) => {
        this.errorHandler.handleError(error);
        return EMPTY;
      }),
      tap((newRecommendation: RecommendationDto) => {
        if (newRecommendation) {
          const currentState = getState();
          const updatedRecommendations = [...currentState.recommendations, newRecommendation];
          patchState({
            ...currentState,
            recommendations: updatedRecommendations,
          });
        }
      })
    );
  }

  handleError(error: ApiErrorResponse) {
    if (error) {
      this.errorHandler.handleError(error);
    }
    return EMPTY;
  }
}
