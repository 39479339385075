<div class="machine-overview-container">
  <div class="section">
    <geaui-table-v2
      [tableId]="TABLE_ID"
      [columnDefinitions]="columnDefinitions"
      [data]="machineOverviewData"
      [totalRecords]="totalRecords"
      [sortable]="true"
      [loading]="loading">
    </geaui-table-v2>
  </div>
  <div class="section page-footer">
    <geaui-button-v2
      class="save-button"
      [type]="'primary'"
      [disabled]="!hasMachinesChanged"
      [sending]="loading"
      (click)="saveMachineNames()">
      <span>{{ 'MACHINE_OVERVIEW.SAVE_BUTTON' | translate }}</span>
    </geaui-button-v2>
  </div>
</div>
