import { Injectable } from '@angular/core';

import { SelectOption } from '@gea/digital-ui-lib';
import { Observable, catchError, first, map, of } from 'rxjs';

import { HrtApiService } from './hrt-api.service';
import { FaultDto, FaultAnalysisDto, MachineFaultDto, MachineAndFaultDto } from '@shared/models';
import { DateHelper } from '../helpers';

const WEEK_DATA_AGGREGATION_THRESHOLD = 14;

@Injectable({
  providedIn: 'root',
})
export class FaultsApiService {
  faultsUrl = (machineId: number) => `machines/${machineId}/faults`;
  faultAnalysisUrl = 'faultanalysis';

  constructor(readonly api: HrtApiService) {}

  fetchFaultCodesForMachine(machineId: number): Observable<SelectOption[]> {
    return this.api.getForOrganization<FaultDto[]>(this.faultsUrl(machineId)).pipe(
      map((items) =>
        items.map(
          (item) =>
            ({
              key: item.name,
              value: item.faultCode,
              name: item.name,
            }) as SelectOption
        )
      )
    );
  }

  fetchFaultCodes(
    machines: number[],
    dateRange: string = DateHelper.daysBack(28),
    faultStatus: string[] = []
  ): Observable<{ faultCodes: SelectOption[]; faultCodeGroups: SelectOption[]; faultAnalysis: FaultAnalysisDto }> {
    return this.api
      .getForOrganization<FaultAnalysisDto>(this.faultAnalysisUrl, { params: { machines, dateRange, faultStatus } })
      .pipe(
        map((faultAnalysis) => ({
          faultCodes: this.parseFaultCodes(faultAnalysis, machines),
          faultCodeGroups: this.parseFaultCodeGroups(faultAnalysis.machines),
          faultAnalysis,
        })),
        catchError(() =>
          of({
            faultCodes: [],
            faultCodeGroups: [],
            faultAnalysis: { machines: [], shutdowns: [], warnings: [], faultHistory: [] },
          })
        ),
        first()
      );
  }

  fetchFaultAnalysis(
    machines: number[],
    dateRange: string,
    faults: number[],
    faultStatus: string[]
  ): Observable<FaultAnalysisDto> {
    const aggregation = this.getDataAggregationType(dateRange);

    return this.api
      .getForOrganization<FaultAnalysisDto>(`${this.faultAnalysisUrl}`, {
        params: { machines, dateRange, faults, faultStatus, aggregation },
      })
      .pipe(
        map((faultAnalysis) => {
          return {
            ...faultAnalysis,
            shutdowns: this.normalizeCountPendingRelative(faultAnalysis.shutdowns),
            warnings: this.normalizeCountPendingRelative(faultAnalysis.warnings),
          };
        })
      );
  }

  // Helper method to determine data aggregation type based on date range
  private getDataAggregationType(dateRange: string): 'DAY' | 'WEEK' {
    return Number(dateRange) > WEEK_DATA_AGGREGATION_THRESHOLD ? 'WEEK' : 'DAY';
  }

  // Normalize the relative count of pending issues in fault data
  private normalizeCountPendingRelative<T extends MachineFaultDto>(faults: T[]): T[] {
    const totalPending = faults.reduce((total, fault) => total + fault.countPending, 0);
    return faults.map((fault) => ({ ...fault, countPendingRelative: totalPending ? fault.countPending / totalPending : 0 }));
  }

  // Extract fault codes from fault analysis data
  private parseFaultCodes(faultAnalysis: FaultAnalysisDto, machineIds: number[]): SelectOption[] {
    return [...faultAnalysis.shutdowns, ...faultAnalysis.warnings]
      .filter(({ machineId }) => machineIds.includes(machineId)) // select warnings and shutdown codes for the given machine
      .map(({ faultCode, faultName }) => ({ name: faultName, value: faultCode })) // create SelectOptions
      .reduce((acc, current) => {
        // filter out duplicate faults
        const x = acc.find(({ name, value }) => name === current.name && value === current.value);
        if (!x) {
          acc.push(current);
        }
        return acc;
      }, [] as SelectOption[]);
  }

  // Collect unique fault code groups from machines data
  private parseFaultCodeGroups(machines: MachineAndFaultDto[]): SelectOption[] {
    const faultCodeGroups = new Map<number, string>();

    machines.forEach((machine) => {
      machine.faults.forEach((fault) => {
        if (!faultCodeGroups.has(fault.faultCodeGroup)) {
          faultCodeGroups.set(fault.faultCodeGroup, fault.name);
        }
      });
    });

    return Array.from(faultCodeGroups, ([value, name]) => ({ name, value }));
  }
}
