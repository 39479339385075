import { ChangeDetectorRef, Component, DestroyRef, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { ColumnDefinition, TableServiceV2 } from '@gea/digital-ui-lib';

import { BaseFault, FaultCatalogType, Shutdown, Warning } from '@shared/models';
import {
  Fix_ACTION,
  shutdownDefinitions,
  warningDefinitions,
} from '@fault-analysis/table-config/fault-analysis-column-definitions';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { sortTableData } from '@fault-analysis/table-config/table.util';
import { filter } from 'rxjs';
import { TranslateEnumService } from '@shared/services';

const generateText = (type: FaultCatalogType, text: 'TITLE' | 'TOOLTIP') => `FAULT_ANALYSIS.${type.toUpperCase()}S.${text}`;

@Component({
  selector: 'gea-hrt-fault-analysis-section',
  templateUrl: './section.component.html',
  styleUrl: './section.component.scss',
})
export class FaultAnalysisSectionComponent implements OnInit, OnChanges {
  columnDefinitions!: ColumnDefinition[];
  showTable = true;
  TABLE_ID!: `${FaultCatalogType}-table`;
  placeholder!: string;
  title!: string;
  tooltip!: string;

  @Input({ required: true }) data!: Warning[] | Shutdown[];
  @Input({ required: true }) set type(type: FaultCatalogType) {
    this.TABLE_ID = `${type}-table`;
    this.placeholder = this.translateService.GetTranslationPlural(type);
    this.title = generateText(type, 'TITLE');
    this.tooltip = generateText(type, 'TOOLTIP');
    this.columnDefinitions = type === 'shutdown' ? shutdownDefinitions : warningDefinitions;
  }

  @Output() resolveFaultCodeOutput = new EventEmitter<BaseFault>();

  public currentDate = new Date();

  constructor(
    readonly tableService: TableServiceV2,
    readonly destroyRef: DestroyRef,
    readonly translateService: TranslateEnumService,
    readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.currentDate = new Date();

    this.tableService
      .getFilterTableSettings(this.TABLE_ID)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((tableFilter) => {
        this.data = sortTableData(tableFilter, this.data);
      });

    this.tableService.actions
      .pipe(
        filter((action) => action.tableId === this.TABLE_ID),
        filter((action) => action.action === Fix_ACTION),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((action) => {
        this.resolveFaultCodeOutput.emit(action.rowData);
      });
  }

  ngOnChanges() {
    this.currentDate = new Date();
    this.changeDetectorRef.detectChanges(); // Trigger change detection
  }
}
