import { Component, DestroyRef, OnInit } from '@angular/core';
import { ApiErrorResponse, ColumnDefinition, ErrorHandlerV2Service, SnackbarService, TableServiceV2 } from '@gea/digital-ui-lib';
import {
  EDIT_MACHINE_ACTION,
  machineColumnDefinitionsConfig,
} from '@tab-container/machine-overview/table-config/machine-column-definitions.config';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Select, Store } from '@ngxs/store';
import { MachineOverviewModel, Permission } from '@shared/models';
import { filter, first, Observable, switchMap } from 'rxjs';
import { FetchMachines, MachinesState, OrganizationsState, SetMachineOverviewData } from '@shared/state';
import { OrganizationsApiService } from '@shared/services';

@Component({
  selector: 'gea-hrt-machine-overview',
  templateUrl: './machine-overview.component.html',
  styleUrl: './machine-overview.component.scss',
})
export class MachineOverviewComponent implements OnInit {
  machineOverviewData: MachineOverviewModel[] = [];
  protected readonly TABLE_ID = 'machine-overview-table';
  protected columnDefinitions: ColumnDefinition[] = [];
  protected totalRecords = 0;
  protected hasMachinesChanged = false;
  protected loading = false;

  @Select(OrganizationsState.permission) _permissions$!: Observable<Permission>;

  constructor(
    readonly store: Store,
    private readonly destroyRef: DestroyRef,
    private readonly organizationsApi: OrganizationsApiService,
    private readonly errorHandler: ErrorHandlerV2Service,
    private readonly snackbarService: SnackbarService,
    private readonly tableV2Service: TableServiceV2
  ) {}

  ngOnInit(): void {
    let perm: Permission = 'customer';
    this._permissions$.subscribe((permissions) => {
      perm = permissions;
    });
    this.columnDefinitions = machineColumnDefinitionsConfig(perm);
    this.store
      .dispatch(new SetMachineOverviewData())
      .pipe(
        switchMap(() => {
          return this.store.select(MachinesState.machineOverviewData).pipe(
            filter((machines) => machines.length > 0),
            first()
          );
        })
      )
      .subscribe((machines) => (this.machineOverviewData = machines));

    this.tableV2Service.actions
      .pipe(
        filter((action) => action.tableId === this.TABLE_ID),
        filter((action) => action.action === EDIT_MACHINE_ACTION),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((action) => this.updateMachines(action.rowData));
  }

  saveMachineNames() {
    const machines = this.machineOverviewData.map((m) => ({ id: m.machine.id, name: m.machine.name }));

    const organizationId = this.store.selectSnapshot(OrganizationsState.selectedId);
    if (!organizationId) return;

    this.loading = true;
    this.organizationsApi.updateMachineNames(organizationId, machines).subscribe({
      next: () => this.onSaveSuccess(),
      error: (error: ApiErrorResponse) => this.onSaveError(error),
    });
  }

  private updateMachines(newMachine: MachineOverviewModel) {
    const index = this.machineOverviewData.findIndex((m) => m.machine.id === newMachine.machine.id);

    if (index !== -1) {
      const updatedMachineOverviewData = [...this.machineOverviewData];
      updatedMachineOverviewData[index] = newMachine;
      this.machineOverviewData = updatedMachineOverviewData;
      this.hasMachinesChanged = true;
    }
  }

  private onSaveSuccess() {
    this.loading = false;
    this.hasMachinesChanged = false;
    this.store.dispatch(new FetchMachines());
    this.snackbarService.add({
      summary: 'MACHINE_OVERVIEW.SNACKBAR.SAVE_MACHINE.SUMMARY',
      detail: 'MACHINE_OVERVIEW.SNACKBAR.SAVE_MACHINE.DETAIL',
      severity: 'success',
    });
  }

  private onSaveError(error: ApiErrorResponse) {
    this.loading = false;
    this.errorHandler.handleError(error);
  }
}
