<div>
  <input
    #inputField
    [value]="rowData?.machine?.name"
    (blur)="onFocusOut(inputField)"
    (keydown.enter)="inputField.blur()"
    type="text" />
  <geaui-icon-v2
    class="icon"
    pTooltip="{{ 'MACHINE_OVERVIEW.UNDO_ICON' | translate }}"
    tooltipPosition="top"
    tooltipStyleClass="p-tooltip-light-blue"
    [file]="'16px_undo'"
    color="black"
    (click)="onSetMachineNameCRM(inputField)">
  </geaui-icon-v2>
</div>
