import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MsalGuard } from '@azure/msal-angular';
import { TranslateModule } from '@ngx-translate/core';

import { FaultAnalysisComponent } from './tab-container/fault-analysis';
import { PermissionsGuard } from './shared/guards/permissions.guard';
import { SystemHealthComponent } from './tab-container/system-health/system-health.component';

import { FaultRecommendationsComponent } from './tab-container/fault-recommendations';
import { TabContainerComponent } from './tab-container/tab-container.component';
import { TroubleshootingComponent } from './tab-container/troubleshooting';
import { MachineOverviewComponent } from '@tab-container/machine-overview/machine-overview.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'fault-analysis',
    pathMatch: 'full',
  },
  {
    path: 'state',
    redirectTo: 'fault-analysis',
    pathMatch: 'full',
  },
  {
    path: '',
    component: TabContainerComponent,
    canActivate: [MsalGuard],
    children: [
      {
        path: 'fault-analysis/:organizationId',
        component: FaultAnalysisComponent,
        canActivate: [MsalGuard],
      },
      {
        path: 'system-health/:organizationId',
        component: SystemHealthComponent,
        canActivate: [MsalGuard, PermissionsGuard],
      },
      {
        path: 'recommendations/:organizationId',
        component: FaultRecommendationsComponent,
        canActivate: [MsalGuard, PermissionsGuard],
      },
      {
        path: 'troubleshooting/:organizationId',
        component: TroubleshootingComponent,
        canActivate: [MsalGuard],
      },
      {
        path: 'machine-overview/:organizationId',
        component: MachineOverviewComponent,
        canActivate: [MsalGuard],
      },
    ],
  },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    TranslateModule,
    RouterModule.forRoot(routes, {
      useHash: true,
      // Don't perform initial navigation in iframes
      initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled',
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
