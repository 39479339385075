<div class="fault-analysis-container">
  <div class="section filters">
    <ng-container *ngIf="selectedMachines$ | async as selectedMachines">
      <ng-container *ngIf="selectedFaults$ | async as selectedFaultCodes">
        <gea-hrt-fault-filters
          [dateRange]="dateRange"
          [machineOptions]="machineOptions"
          [faultCodeOptions]="faultOptions"
          [selectedMachines]="selectedMachines | toSelectOptions"
          [selectedFaultCodes]="selectedFaultCodes | toSelectOptions"
          (setMachines)="setSelectedMachines($event)"
          (setFaultCodes)="setSelectedFaultCodes($event)"
          (setDateRange)="setDateRange($event)"
          (setStatus)="setStatus($event)" />
      </ng-container>
    </ng-container>
  </div>
  <div class="section shutdowns" *ngIf="shutdowns$ | async as shutdowns">
    <gea-hrt-fault-analysis-section [data]="shutdowns" type="shutdown" (resolveFaultCodeOutput)="resolveFaultCode($event)" />
  </div>
  <div class="section warnings" *ngIf="warnings$ | async as warnings">
    <gea-hrt-fault-analysis-section [data]="warnings" type="warning" (resolveFaultCodeOutput)="resolveFaultCode($event)" />
  </div>
  <div class="section fault-history">
    <gea-hrt-fault-history [faultHistory]="faultHistory$ | async" />
  </div>
</div>
