import { Organization } from '@shared/models';
import { SimpleComparator } from '@shared/helpers';

export class OrganizationsComparator {
  static by(columnName: string, sortingOrder: string) {
    if (columnName !== 'faults') {
      return SimpleComparator.by<Organization>(columnName, sortingOrder);
    } else {
      return OrganizationsComparator.byFault(sortingOrder);
    }
  }

  static byFault(sortOrder: string) {
    const order = sortOrder === 'asc' ? 1 : -1;
    const reverseOrder = order * -1;

    //SortingOrder by pending shutdowns then by pending warnings then by name
    return (a: Organization, b: Organization) => {
      if (a.countShutdowns > b.countShutdowns) {
        return order;
      } else if (a.countShutdowns < b.countShutdowns) {
        return reverseOrder;
      } else {
        // pending shutdowns are the same
        if (a.countWarnings > b.countWarnings) {
          return order;
        } else if (a.countWarnings < b.countWarnings) {
          return reverseOrder;
        } else {
          // pending shutdowns and warnings are the same
          return SimpleComparator.by<Organization>('name', sortOrder)(a, b);
        }
      }
    };
  }
}
