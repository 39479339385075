import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, Subject, combineLatest } from 'rxjs';
import { first, skipWhile, takeUntil } from 'rxjs/operators';
import { Select, Store } from '@ngxs/store';
import { SelectOption } from '@gea/digital-ui-lib';
import { GetHealthCheck, MachinesState, OrganizationsState, SetSelectedMachines, SystemHealthState } from '@shared/state';
import { MachineStatus, SystemHealthMachineComponent } from '@shared/models';

@Component({
  selector: 'gea-hrt-system-health',
  templateUrl: './system-health.component.html',
  styleUrls: ['./system-health.component.scss'],
})
export class SystemHealthComponent implements OnInit, OnDestroy {
  @Select(SystemHealthState.lastHealthCheckTime) lastHealthCheckTime$!: Observable<string>;
  @Select(SystemHealthState.components) components$!: Observable<SystemHealthMachineComponent[]>;
  @Select(SystemHealthState.machineStatus) machineStatus$!: Observable<MachineStatus>;

  @Select(MachinesState.machines) machineOptions$!: Observable<SelectOption[]>;
  @Select(MachinesState.selectedMachine) selectedMachine$!: Observable<number>;
  @Select(OrganizationsState.selectedId) organizationId$!: Observable<number>;

  private readonly ngDestroyed$ = new Subject<void>();
  machine?: number;

  constructor(
    readonly store: Store,
    readonly route: ActivatedRoute,
    readonly router: Router
  ) {}

  ngOnInit() {
    this.initializeSubscriptions();
  }

  ngOnDestroy() {
    this.ngDestroyed$.next();
    this.ngDestroyed$.complete();
  }

  private initializeSubscriptions() {
    combineLatest([
      this.route.queryParams,
      this.machineOptions$.pipe(
        skipWhile((options) => options.length < 1),
        first()
      ),
      this.selectedMachine$.pipe(first()),
    ])
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe(([params, machines, selectedMachine]) => {
        this.initSelectedMachine(params, machines, selectedMachine);
      });
  }

  private initSelectedMachine(params: Params, machines: SelectOption<number>[], selectedMachine?: number) {
    let machine = selectedMachine ?? machines[0].value;
    const queryMachine = parseInt(params['machine'], 10);

    if (!isNaN(queryMachine)) {
      machine = queryMachine;
    }

    this.setMachine(machine);
  }

  protected setQueryParams(queryParams: { machine?: number }) {
    this.router.navigate([], { queryParams, queryParamsHandling: 'merge' }).catch(() => {
      console.warn('Query parameter could not be set');
    });
  }

  protected fetchData() {
    if (this.machine !== undefined) {
      this.store.dispatch(new GetHealthCheck(this.machine));
    }
  }

  setMachine(machine: number) {
    this.machine = machine;
    this.store.dispatch(new SetSelectedMachines([machine]));
    this.setQueryParams({ machine });
    this.fetchData();
  }

  trackByComponentId(_: number, component: SystemHealthMachineComponent) {
    return component.id;
  }
}
