import { Component, Output, EventEmitter, Input } from '@angular/core';
import { RecommendationDto, ProblemResolveDto } from '@shared/models';
import { RecommendationAction } from './recommendations-list';

@Component({
  selector: 'gea-hrt-recommendations',
  templateUrl: './recommendations.component.html',
  styleUrls: ['./recommendations.component.scss'],
})
export class RecommendationsComponent {
  @Input({ required: true }) loading!: boolean;
  @Output() handleRecommendationAction = new EventEmitter<RecommendationAction>();
  @Output() emitProblemSolved = new EventEmitter<ProblemResolveDto | undefined>();

  @Input() recommendationText?: string;
  @Input({ required: true }) recommendations!: RecommendationDto[];
}
