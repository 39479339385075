import { Component } from '@angular/core';
import { Warning, Shutdown } from '@app/shared/models';
import { ColumnRendererComponent } from '@gea/digital-ui-lib';

@Component({
  selector: 'gea-hrt-last-instance-renderer',
  template: `
    @if (rowData) {
      <gea-hrt-update-time [date]="rowData.timeStamp" />
    }
  `,
})
export class LastInstanceRendererComponent implements ColumnRendererComponent<Warning | Shutdown> {
  rowData?: Warning | Shutdown;
}
