<div class="solve-dialog-container">
  <div mat-dialog-title class="solve-dialog-header">
    <gea-hrt-heading class="dialog-title" type="sub"
      >{{ 'TROUBLESHOOTING.PROBLEM_SOLVING.DIALOG_TITLE' | translate }}
    </gea-hrt-heading>
    <gea-hrt-close-dialog-button (closeDialog)="closeDialog()" />
  </div>
  <mat-dialog-content>
    <div class="input selected-recommendations">
      <div>{{ 'TROUBLESHOOTING.PROBLEM_SOLVING.DIALOG_SELECT_SOLUTION' | translate }}(Optional)</div>
      <geaui-multi-select-v2
        id="selectedRecommendations"
        [disabled]="noneOfTheAbove"
        [group]="formGroup"
        [selected]="selectedRecommendations"
        [options]="recommendationsOptions" />

      <geaui-checkbox-v2
        label="{{ 'TROUBLESHOOTING.PROBLEM_SOLVING.SOLVE_DIALOG.NONE_OF_THE_ABOVE' | translate }}"
        [id]="'input'"
        (changed)="toggleSelection($event)" />

      <div class="input additional-note">
        <div>{{ 'TROUBLESHOOTING.PROBLEM_SOLVING.SOLVE_DIALOG.ADDITIONAL_NOTE' | translate }}(optional)</div>
        <geaui-textfield-v2 id="additionalNote" [group]="formGroup" />
        <div class="max-chars-hint">
          {{
            'TROUBLESHOOTING.PROBLEM_SOLVING.DIALOG_MAX_LENGTH'
              | translate: { length: formGroup.controls.additionalNote.value?.length, maxLength: 1000 }
          }}
        </div>
      </div>
    </div>

    <gea-hrt-images-preview [images]="formGroup.get('images')?.value" [formGroup]="formGroup" />

    <div class="buttons">
      <geaui-button-v2 buttonType="secondary" (click)="closeDialog()">
        {{ 'TROUBLESHOOTING.PROBLEM_SOLVING.DIALOG_BUTTON_CANCEL' | translate }}
      </geaui-button-v2>
      <geaui-button-v2
        data-testid="button-save"
        [disabled]="
          !formGroup.controls.selectedRecommendations.value?.length && formGroup.controls.additionalNote.value?.length < 10
        "
        (click)="save()">
        {{ 'TROUBLESHOOTING.PROBLEM_SOLVING.DIALOG_BUTTON_SAVE' | translate }}
      </geaui-button-v2>
    </div>
  </mat-dialog-content>
</div>
