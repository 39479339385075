export function hashCode(value: object): number {
  const str = stringify(value);
  let h = 0;
  for (let i = 0; i < str.length; i++) {
    h = 31 * h + str.charCodeAt(i);
  }
  return h & 0xffffffff;
}

export function stringify(obj: object): string {
  let str = '';
  for (const [key, value] of Object.entries(obj)) {
    if (!/^_.*/.test(key)) {
      let _value = '';
      try {
        _value = JSON.stringify(value, (k, v) => {
          if (typeof v === 'undefined') {
            return 'undefined';
          }
          if (typeof k === 'symbol') {
            return 'a symbol';
          }
          return v;
        });
      } catch (e) {
        _value = '';
      }
      str += `${key}:${_value};;`;
    }
  }
  return str;
}
