import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ApiErrorResponse, ErrorHandlerV2Service } from '@gea/digital-ui-lib';
import { catchError, of, tap } from 'rxjs';

import { FetchRelatedOperatingData } from './related-operating-data.action';
import { RelatedOperatingDataApiService } from '@shared/services';
import { RelatedOperatingDataStateModel, MeasurementSerieDto } from '@shared/models';

import { CachedAction, DateHelper } from '@shared/helpers';

@State<RelatedOperatingDataStateModel>({
  name: 'relatedOperatingData',
  defaults: {
    measurements: [],
  },
})
@Injectable()
export class RelatedOperatingDataState {
  @Selector()
  static measurements(state: RelatedOperatingDataStateModel): MeasurementSerieDto[] {
    return state.measurements;
  }

  constructor(
    readonly relatedOperatingDataApi: RelatedOperatingDataApiService,
    readonly errorHandler: ErrorHandlerV2Service
  ) {}

  @CachedAction(0)
  @Action(FetchRelatedOperatingData)
  fetchRelatedOperatingData({ patchState }: StateContext<RelatedOperatingDataStateModel>, action: FetchRelatedOperatingData) {
    return this.relatedOperatingDataApi
      .fetchRelatedOperatingData(
        action.machine,
        DateHelper.hoursBack(action.dateHours),
        action.temperatureUnit,
        action.pressureUnit
      )
      .pipe(
        catchError((error: ApiErrorResponse) => {
          if (error?.headers) {
            this.errorHandler.handleError(error);
          }
          return of([]);
        }),
        tap((measurements) => {
          patchState({
            measurements,
          });
        })
      );
  }
}
