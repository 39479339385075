<ng-container>
  <ng-container [ngSwitch]="recommendation.status">
    <span class="label variant-skipped" *ngSwitchCase="Status.SKIPPED" data-testid="skipped-label">
      {{ 'TROUBLESHOOTING.RECOMMENDATIONS.LABEL_SKIPPED' | translate }}
    </span>
    <span class="label variant-done" *ngSwitchCase="Status.DONE" data-testid="done-label">
      {{ 'TROUBLESHOOTING.RECOMMENDATIONS.LABEL_DONE' | translate }}
    </span>
    <geaui-button-v2
      *ngSwitchCase="Status.PENDING"
      [type]="'secondary'"
      data-testid="btn-skip-task"
      (click)="actionClicked.emit({ recommendationId: recommendation.id, statusToSet: Status.SKIPPED })"
      >{{ 'TROUBLESHOOTING.RECOMMENDATIONS.BUTTON_SKIP_TASK' | translate }}
    </geaui-button-v2>
  </ng-container>
  <ng-container [ngSwitch]="recommendation.status === Status.PENDING">
    <geaui-button-v2
      *ngSwitchCase="true"
      [type]="'secondary'"
      data-testid="btn-done"
      (click)="actionClicked.emit({ recommendationId: recommendation.id, statusToSet: Status.DONE })"
      >{{ 'TROUBLESHOOTING.RECOMMENDATIONS.BUTTON_DONE' | translate }}</geaui-button-v2
    >
    <geaui-icon-v2
      *ngSwitchCase="false"
      title="{{ 'TROUBLESHOOTING.RECOMMENDATIONS.BUTTON_RESET' | translate }}"
      file="reload"
      data-testid="btn-reset"
      (click)="actionClicked.emit({ recommendationId: recommendation.id, statusToSet: Status.PENDING })" />
  </ng-container>
</ng-container>
