import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'gea-hrt-images-preview',
  templateUrl: './images-preview.component.html',
  styleUrl: './images-preview.component.scss',
})
export class ImagesPreviewComponent {
  @Input({ required: true }) images: (string | ArrayBuffer[])[] = [];
  @Input({ required: true }) formGroup: FormGroup | undefined;

  show5mbWarning = false;
  trackByIndex(index: number): number {
    return index;
  }

  onDeleteImage(index: number) {
    const imagesControl = this.formGroup?.get('images') as FormControl;

    if (imagesControl) {
      const images = imagesControl.value as string[];

      if (images && index >= 0 && index < images.length) {
        const updatedImages = [...images]; // Create a shallow copy of the array
        updatedImages.splice(index, 1);
        imagesControl.setValue(updatedImages);
        this.images = updatedImages;
      }
    }

    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  }

  onAddImage() {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  }

  onFileSelected(files: Event) {
    const inputElement = files.target as HTMLInputElement;

    Array.from(inputElement?.files ?? []).forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        const imagesControl = this.formGroup?.get('images');

        if (!imagesControl) {
          return;
        }

        const imagesint = imagesControl.value;
        this.images = [...imagesint, reader.result];
        imagesControl.setValue([...imagesint, reader.result]);
      };

      const size = file.size / 1024 / 1024;
      if (size > 5) {
        this.show5mbWarning = true;
        return;
      }
      this.show5mbWarning = false;
      reader.readAsDataURL(file);
    });
  }
}
