import { MachineFaultDto } from '@shared/models';
import { SimpleComparator } from '@shared/helpers';

export class FaultComparator {
  static by(columnName: string, sortingOrder: string) {
    if (columnName === 'totalPending') {
      return SimpleComparator.by<MachineFaultDto>('countPending', sortingOrder);
    } else {
      return SimpleComparator.by<MachineFaultDto>(columnName, sortingOrder);
    }
  }
}
