import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FaultCatalogType } from '@shared/models';

@Injectable({
  providedIn: 'root',
})
export class TranslateEnumService {
  constructor(readonly translationService: TranslateService) {}

  GetTranslationPlural(type: FaultCatalogType): string {
    return this.translationService.instant('ENUM.FAULT_CATALOG_TYPE.' + type.toLocaleUpperCase() + 'S');
  }

  GetTranslationSingular(type: 'open' | 'useful' | 'discarded') {
    return this.translationService.instant('ENUM.SOLVED_PROBLEM_STATUS.' + type.toLocaleUpperCase());
  }
}
