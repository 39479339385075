import { ActivatedRoute } from '@angular/router';
import { Inject, Injectable } from '@angular/core';

import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { ApiErrorResponse, ErrorHandlerV2Service } from '@gea/digital-ui-lib';
import { catchError, first, of, take } from 'rxjs';

import { ClearRecommendations } from '@shared/state/recommendations';
import { ClearMachines, FetchMachines } from '@shared/state/machines';
import { OrganizationsApiService } from '@shared/services';
import { OrganizationsComparator } from './organizations.comparator';
import { FetchOrganizations, FilterOrganizations, SetSelectedOrganization, SortOrganizations } from './organizations.action';
import { Organization, OrganizationsStateModel, MembershipModel, Permission, OrganizationPermission } from '@shared/models';
import { MembershipsState } from '@shared/state/memberships/memberships.state';

@State<OrganizationsStateModel>({
  name: 'organizations',
  defaults: {
    organizations: [],
    filteredOrganizations: [],
    selected: undefined,
  },
})
@Injectable()
export class OrganizationsState {
  constructor(
    @Inject('isAdminInLocal') protected isAdminInLocal: boolean,
    @Inject(Store) protected store: Store,
    readonly organizationsApi: OrganizationsApiService,
    readonly route: ActivatedRoute,
    readonly errorHandler: ErrorHandlerV2Service
  ) {}

  @Selector()
  static organizations(state: OrganizationsStateModel): Organization[] {
    return state.organizations;
  }

  @Selector()
  static filteredOrganizations(state: OrganizationsStateModel): Organization[] {
    return state.filteredOrganizations;
  }

  @Selector()
  static selected(state: OrganizationsStateModel): OrganizationPermission | undefined {
    return state.selected;
  }

  @Selector()
  static permission(state: OrganizationsStateModel): Permission | undefined {
    return state.selected?.permission ?? undefined;
  }

  @Selector()
  static selectedId(state: OrganizationsStateModel): number | undefined {
    return state.selected?.organization?.id;
  }

  @Action(FetchOrganizations)
  fetchOrganizations({ patchState, dispatch }: StateContext<OrganizationsStateModel>) {
    this.organizationsApi
      .fetchOrganizations()
      .pipe(
        catchError((error: ApiErrorResponse) => {
          if (error) {
            this.errorHandler.handleError(error);
          }
          return of([]);
        }),
        first()
      )
      .subscribe((organizations) => {
        patchState({
          organizations,
          filteredOrganizations: organizations,
        });
        const snapshot = this.route.firstChild?.firstChild?.snapshot;
        const organizationId = parseInt(snapshot?.params.organizationId as string);
        const organization = organizations.find(
          (_organization: Organization) => _organization.id === organizationId
        ) as Organization;
        if (organization) {
          dispatch(new SetSelectedOrganization(organization));
        }
      });
  }

  @Action(SetSelectedOrganization)
  setSelectedOrganization({ patchState, dispatch }: StateContext<OrganizationsStateModel>, action: SetSelectedOrganization) {
    if (action.organization) {
      let x: MembershipModel[] = [];
      this.store
        .select(MembershipsState.memberships)
        .pipe(take(1))
        .subscribe((p) => (x = p));
      const per = this.getPermissionForSelectedOrganisation(action.organization, x);
      patchState({
        selected: {
          organization: action.organization,
          permission: per,
        },
      });

      dispatch(new ClearMachines());
      dispatch(new FetchMachines());
      dispatch(new ClearRecommendations());
      dispatch(new SortOrganizations('faults', 'desc', action.organization.id)); // reset sorting on customer selection
    }
  }

  getPermissionForSelectedOrganisation(organization: Organization, memberships: MembershipModel[]): Permission {
    const membership = memberships?.find((m) => m.organization.name === organization.name);
    const roleName = membership?.role.name ?? '';
    return this.createPermission(roleName);
  }

  createPermission(roleName: string): Permission {
    if (this.isAdminInLocal) return 'admin';

    switch (roleName) {
      case 'APP_hrt_insightpartner-bluredcare_admin':
      case 'GEA_App_Administrator':
      case 'GEA_App_Operator':
        return 'admin';
      case 'admin':
        return 'customerAdmin';
      case 'gea_read_only':
        return 'customer';
      case 'maintenance_manager':
        return 'maintenance_manager';
      case 'technician':
        return 'technician';
      case 'executive_board':
        return 'executive_board';
      default:
        return 'customer';
    }
  }

  @Action(SortOrganizations)
  sortOrganizations({ patchState, getState }: StateContext<OrganizationsStateModel>, action: SortOrganizations) {
    let customerList = [...getState().filteredOrganizations];
    customerList.sort(OrganizationsComparator.by(action.columName, action.sortOrder));

    customerList = this.moveSelectedOrganizationToTopOfList(customerList, action.organizationId);

    if (customerList) {
      patchState({
        filteredOrganizations: customerList,
      });
    }
  }

  @Action(FilterOrganizations)
  filterCustomer({ patchState, getState }: StateContext<OrganizationsStateModel>, action: FilterOrganizations) {
    const customerList = [...getState().organizations];
    let filteredOrganizations = customerList.filter((customer: Organization) => {
      return customer.name.toLowerCase().indexOf(action.filterString.toLowerCase()) !== -1;
    });

    filteredOrganizations = this.moveSelectedOrganizationToTopOfList(
      filteredOrganizations,
      getState().selected?.organization?.id
    );

    if (filteredOrganizations) {
      patchState({
        filteredOrganizations,
      });
    }
  }

  private moveSelectedOrganizationToTopOfList(organizations: Organization[], organizationId: number | undefined): Organization[] {
    if (!organizationId) {
      return organizations;
    }

    const selected = organizations.find((org) => org.id === organizationId);
    if (selected) {
      return [selected, ...organizations.filter((org) => org.id !== selected.id)];
    }

    return organizations;
  }
}
