import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  Output,
  OnDestroy,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { RecommendationReviewStatus, ResolvedFault } from '@app/shared/models';
import {
  ColumnDefinition,
  ColumnRendererComponent,
  FilterTableSettings,
  TableServiceV2,
  TextFilterComponent,
} from '@gea/digital-ui-lib';
import { TranslateService } from '@ngx-translate/core';
import { Subject, filter, takeUntil } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SolvedFaultsDetailDialogComponent } from './detail-dialog/detail-dialog.component';
import { RecommendationImagesDialogComponent } from '@tab-container/troubleshooting';
import {
  DateRendererComponent,
  ResolvedFaultStatusRendererComponent,
  SolvedFaultImagesRendererComponent,
  SolvedFaultViewDetailRendererComponent,
} from './table-renderers';

export const VIEW_SOLVED_FAULT_DETAIL = 'VIEW_SOLVED_FAULT_DETAIL';
export const VIEW_SOLVED_FAULT_IMAGES = 'VIEW_SOLVED_FAULT_IMAGES';
export const columnDefinitions: ColumnDefinition[] = [
  {
    key: 'faultCode',
    displayName: 'FAULT_RECOMMENDATIONS.SOLVED_FAULTS.FAULT_CODE',
    width: 0.1,
  },
  {
    key: 'faultName',
    displayName: 'FAULT_RECOMMENDATIONS.SOLVED_FAULTS.SOLVED_FAULT',
    resizeable: true,
    width: 1,
    filter: {
      component: TextFilterComponent,
    },
  },
  {
    key: 'additionalNote',
    displayName: 'FAULT_RECOMMENDATIONS.SOLVED_FAULTS.ADDITIONAL_NOTE',
    sortable: false,
    resizeable: true,
  },
  {
    key: 'images',
    displayName: 'FAULT_RECOMMENDATIONS.SOLVED_FAULTS.IMAGES',
    sortable: false,
    resizeable: true,
    width: 1,
    renderer: {
      component: SolvedFaultImagesRendererComponent as ColumnRendererComponent<ResolvedFault>,
    },
  },
  {
    key: 'customer',
    displayName: 'FAULT_RECOMMENDATIONS.SOLVED_FAULTS.CREATED_BY',
    resizeable: true,
  },
  {
    key: 'solvedOn',
    displayName: 'FAULT_RECOMMENDATIONS.SOLVED_FAULTS.SOLVED_ON',
    resizeable: true,
    width: 0.5,
    renderer: {
      component: DateRendererComponent as ColumnRendererComponent<ResolvedFault>,
      config: {
        columnKey: 'solvedOn',
      },
    },
  },
  {
    key: 'status',
    displayName: 'FAULT_RECOMMENDATIONS.SOLVED_FAULTS.STATUS',
    resizeable: true,
    renderer: {
      component: ResolvedFaultStatusRendererComponent as ColumnRendererComponent<ResolvedFault>,
    },
  },
  {
    key: 'actionBtn',
    sortable: false,
    displayName: '',
    width: 1,
    resizeable: true,
    renderer: {
      component: SolvedFaultViewDetailRendererComponent as ColumnRendererComponent<ResolvedFault>,
    },
  },
];

export type SolvedFaultResponse = { resolvedFault: ResolvedFault; reviewStatus: RecommendationReviewStatus };
@Component({
  selector: 'gea-hrt-solved-faults',
  templateUrl: './solved-faults.component.html',
  styleUrl: './solved-faults.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SolvedFaultsComponent implements OnInit, OnDestroy, OnChanges {
  TABLE_ID = 'SOLVED_FAULTS_TABLE';
  columnDefinitions = columnDefinitions;
  parsedData?: ResolvedFault[];

  @Input({ required: true }) data!: ResolvedFault[];

  @Output() setReviewStatus = new EventEmitter<SolvedFaultResponse>();

  private readonly ngDestroyed$ = new Subject<void>();

  constructor(
    public readonly tableService: TableServiceV2,
    public readonly dialog: MatDialog,
    public readonly translateService: TranslateService
  ) {}

  ngOnInit() {
    this.subscribeToTableActions();
  }

  ngOnDestroy() {
    this.ngDestroyed$.next();
    this.ngDestroyed$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data'] && !changes['data'].firstChange) {
      this.applyFilters();
    }
  }

  private applyFilters() {
    this.tableService
      .getFilterTableSettings(this.TABLE_ID)
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe((tableFilter) => {
        this.parsedData = this.filterAndSortData(this.data, tableFilter);
      });
  }

  private subscribeToTableActions() {
    this.tableService.actions
      .pipe(
        takeUntil(this.ngDestroyed$),
        filter((action) => action.tableId === this.TABLE_ID)
      )
      .subscribe((action) => {
        if (action.action === VIEW_SOLVED_FAULT_DETAIL) {
          this.openResolvedFaultDialog(action);
        } else if (action.action === VIEW_SOLVED_FAULT_IMAGES) {
          this.openResolvedFaultImagesDialog(action);
        }
      });
  }

  private openResolvedFaultDialog({ rowData: resolvedFault }: { rowData: ResolvedFault }) {
    this.dialog
      .open(SolvedFaultsDetailDialogComponent, { data: resolvedFault })
      .afterClosed()
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe((response) => {
        if (response) {
          this.setReviewStatus.emit(response);
        }
      });
  }

  private openResolvedFaultImagesDialog({ rowData: resolvedFault }: { rowData: ResolvedFault }) {
    const images = resolvedFault.images || [];
    this.dialog.open(RecommendationImagesDialogComponent, { data: { images }, autoFocus: true });
  }

  private filterAndSortData(data: ResolvedFault[], filterSettings: FilterTableSettings): ResolvedFault[] {
    const columns = Object.keys(filterSettings.columns);
    if (!data.length || !columns.length) return data;

    return [...data]
      .filter((item) => this.applyColumnFilters(item, filterSettings, columns))
      .sort((a, b) => this.applyColumnSorting(a, b, filterSettings, columns));
  }

  private applyColumnFilters(item: ResolvedFault, filterSettings: FilterTableSettings, columns: string[]): boolean {
    for (const key of columns) {
      const columnFilter = filterSettings.columns[key]?.filter?.[0];
      const itemValue = item[key as keyof ResolvedFault] as string;

      if (columnFilter && typeof itemValue === 'string') {
        const regex = new RegExp(columnFilter, 'i');
        if (!regex.test(itemValue)) return false;
      }
    }
    return true;
  }

  private applyColumnSorting(a: ResolvedFault, b: ResolvedFault, filterSettings: FilterTableSettings, columns: string[]): number {
    for (const key of columns) {
      const sortDirection = filterSettings.columns[key]?.sort;
      if (!sortDirection) continue;

      const aValue = a[key as keyof ResolvedFault];
      const bValue = b[key as keyof ResolvedFault];

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return this.sortStrings(aValue, bValue, sortDirection);
      }
      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return this.sortNumbers(aValue, bValue, sortDirection);
      }
    }
    return 0;
  }

  private sortStrings(a: string, b: string, direction: string): number {
    return direction === 'asc' ? a.localeCompare(b) : b.localeCompare(a);
  }

  private sortNumbers(a: number, b: number, direction: string): number {
    return direction === 'asc' ? a - b : b - a;
  }
}
