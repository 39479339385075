type ValueOf<T> = T[keyof T];

export class SimpleComparator {
  static by<T>(columnName: string, sortingOrder: string) {
    const sortUp = sortingOrder === 'asc' ? 1 : -1;
    const sortDown = sortUp * -1;
    if (columnName) {
      return (a: T, b: T) => {
        const aColumnValue = a[columnName as keyof T];
        const bColumnValue = b[columnName as keyof T];
        if (a && b) {
          return getSortOrder(aColumnValue, bColumnValue, sortUp, sortDown);
        } else {
          return 0;
        }
      };
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return (_a: T, _b: T) => 0;
  }
}

function getSortOrder<T>(aColumnValue: ValueOf<T>, bColumnValue: ValueOf<T>, sortUp: number, sortDown: number) {
  if (aColumnValue > bColumnValue) {
    return sortUp;
  } else if (aColumnValue < bColumnValue) {
    return sortDown;
  } else {
    return 0;
  }
}
