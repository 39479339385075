import { EMPTY, of } from 'rxjs';

import { stringify } from './hash-code';

/**
 * use this decorator to prevent a patchState() if the action is the same as before.
 * @constructor
 */
export function CachedAction(expirationMilliseconds = 0): MethodDecorator {
  return (target: object, method: string | symbol, descriptor: PropertyDescriptor): PropertyDescriptor => {
    /*
    eslint-disable
      no-prototype-builtins,
      @typescript-eslint/no-unsafe-assignment,
      @typescript-eslint/ban-ts-comment,
      @typescript-eslint/no-explicit-any,
      @typescript-eslint/no-unsafe-return,
      @typescript-eslint/no-unsafe-member-access,
      @typescript-eslint/no-unsafe-call
    */
    const cachedMethod = `__cached_${method as string}`;
    const cachedMethodExpiration = `__cached_${method as string}_timestamp`;
    if (!target.hasOwnProperty(cachedMethod)) {
      Object.defineProperty(target, cachedMethod, { writable: true, enumerable: false, value: '' });
      Object.defineProperty(target, cachedMethodExpiration, { writable: true, enumerable: false });
    }
    const originalValue = descriptor.value;
    descriptor.value = function (...args: any[]) {
      const hashedAction = stringify(args[1] as object);
      // @ts-ignore
      const expired = target[cachedMethodExpiration]
        ? // @ts-ignore
          new Date().getTime() - expirationMilliseconds > target[cachedMethodExpiration]
        : true;
      // @ts-ignore
      if (target[cachedMethod] === hashedAction && expired) {
        return of(EMPTY);
      }
      // @ts-ignore
      target[cachedMethod] = hashedAction;
      // @ts-ignore
      target[cachedMethodExpiration] = new Date().getTime();
      return originalValue.apply(this, args);
    };
    return descriptor;
    /*
    eslint-enable
      no-prototype-builtins,
      @typescript-eslint/no-unsafe-assignment,
      @typescript-eslint/ban-ts-comment,
      @typescript-eslint/no-explicit-any,
      @typescript-eslint/no-unsafe-return,
      @typescript-eslint/no-unsafe-member-access,
      @typescript-eslint/no-unsafe-call
    */
  };
}
