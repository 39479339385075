<div class="solve-dialog-container">
  <div mat-dialog-title class="solve-dialog-title">
    <gea-hrt-heading class="dialog-title" type="sub">{{
      'TROUBLESHOOTING.PROBLEM_SOLVING.CONTACT-DIALOG-TITLE' | translate
    }}</gea-hrt-heading>
    <gea-hrt-close-dialog-button (closeDialog)="closeDialog()" />
  </div>
  <mat-dialog-content>
    <div class="input email">
      <geaui-input-v2
        id="email"
        [group]="formGroup"
        label="{{ 'TROUBLESHOOTING.PROBLEM_SOLVING.CONTACT-DIALOG.EMAIL' | translate }}"
        [required]="true"
        autocomplete="off"
        type="mail"
        [canClear]="true" />
    </div>
    <div class="input phone">
      <geaui-input-v2
        id="phone"
        [group]="formGroup"
        label="{{ 'TROUBLESHOOTING.PROBLEM_SOLVING.CONTACT-DIALOG.PHONE' | translate }}"
        [required]="true"
        autocomplete="off"
        type="number"
        [canClear]="true" />
    </div>
    <div class="input title">
      <geaui-input-v2
        id="title"
        [group]="formGroup"
        label="{{ 'TROUBLESHOOTING.PROBLEM_SOLVING.CONTACT-DIALOG.TITLE' | translate }}"
        [required]="true"
        autocomplete="off"
        type="text"
        [canClear]="true" />
      <div class="max-chars-hint">
        {{
          'TROUBLESHOOTING.PROBLEM_SOLVING.DIALOG_MAX_LENGTH'
            | translate: { length: formGroup.value.title.length, maxLength: 100 }
        }}
      </div>
    </div>
    <div class="input message">
      <div>{{ 'TROUBLESHOOTING.PROBLEM_SOLVING.DIALOG_CONTENT' | translate }}</div>
      <geaui-textfield-v2 id="message" [group]="formGroup" />
      <div class="max-chars-hint">
        {{
          'TROUBLESHOOTING.PROBLEM_SOLVING.DIALOG_MAX_LENGTH'
            | translate: { length: formGroup.value.message.length, maxLength: 1000 }
        }}
      </div>
    </div>
    <div class="buttons">
      <geaui-button-v2 buttonType="secondary" (click)="closeDialog()">
        {{ 'TROUBLESHOOTING.PROBLEM_SOLVING.DIALOG_BUTTON_CANCEL' | translate }}
      </geaui-button-v2>
      <geaui-button-v2 data-testid="button-save" [disabled]="!formGroup.valid" (click)="save()">
        {{ 'TROUBLESHOOTING.PROBLEM_SOLVING.DIALOG_BUTTON_SAVE' | translate }}
      </geaui-button-v2>
    </div>
  </mat-dialog-content>
</div>
