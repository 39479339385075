<div class="problem-solve">
  <div *ngIf="showText" class="problem-solve-text">
    {{ 'TROUBLESHOOTING.RECOMMENDATIONS.UNSOLVABLE_PROBLEM_MESSAGE' | translate }}
    <a href="mailto:CustomerPortal@GEA.com" class="contact">
      {{ 'TROUBLESHOOTING.PROBLEM_SOLVING.CONTACT' | translate }}
    </a>
  </div>
  <div class="problem-solve-button">
    <geaui-button-v2
      (click)="openSolutionDescriptionDialog()"
      [disabled]="(permission$ | async) === 'executive_board' || (permission$ | async) === 'customer'">
      {{ 'TROUBLESHOOTING.PROBLEM_SOLVING.BUTTON_PROBLEM_SOLVED' | translate }}
    </geaui-button-v2>
  </div>
</div>
