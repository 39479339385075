<mat-list cdkDropList (cdkDropListDropped)="drop($event)">
  <mat-list-item *ngFor="let item of listForOrdering; let i = index; trackBy: trackById" class="mat-elevation-z3" cdkDrag>
    <gea-hrt-recommendation-item [recommendation]="item" [index]="i" [isDraggable]="true" />
  </mat-list-item>
</mat-list>
<div class="actions">
  <geaui-button-v2 [type]="'secondary'" (click)="cancel()" data-testid="bnt-cancel">
    {{ 'TAB_CONTAINER.FAULT_RECOMMENDATIONS.BUTTON_CANCEL_ORDER' | translate }}
  </geaui-button-v2>
  <geaui-button-v2 (click)="save()" data-testid="bnt-save">
    {{ 'TAB_CONTAINER.FAULT_RECOMMENDATIONS.BUTTON_SAVE_ORDER' | translate }}
  </geaui-button-v2>
</div>
