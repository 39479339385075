import { Component, Input } from '@angular/core';
import { SystemHealthMachineComponent } from '@app/shared/models';

@Component({
  selector: 'gea-hrt-health-component-header',
  templateUrl: './component-header.component.html',
  styleUrl: './component-header.component.scss',
})
export class HealthHeaderComponent {
  @Input({ required: true }) component!: SystemHealthMachineComponent;
}
