import { RecommendationDto, RecommendationStatus, SelectedRecommendation } from '@shared/models';

/**
 * asks for a list of recommendations,
 * that are available for a machine and faultCode
 */
export class FetchRecommendations {
  static readonly type = '[Troubleshooting] Fetch Recommendations';
  constructor(
    readonly machine: number,
    readonly faultCode: number
  ) {}
}

export class FetchRecommendationsByFault {
  static readonly type = '[Troubleshooting] Fetch Recommendations By FaultCode';
  constructor(readonly faultCode: number) {}
}
/**
 * clears any recommendations
 */
export class ClearRecommendations {
  static readonly type = '[Troubleshooting] Clear Recommendations';
}

export class ReorderRecommendations {
  static readonly type = '[Troubleshooting] Reorder Recommendations';
  constructor(readonly recommendations: RecommendationDto[]) {}
}

export class SetRecommendationStatus {
  static readonly type = '[Troubleshooting] Set Recommendation Status';
  constructor(
    readonly machine: number,
    readonly faultCode: number,
    readonly recommendationId: number,
    readonly status: RecommendationStatus
  ) {}
}

export class SolveProblem {
  static readonly type = '[Solve] Solve Problem';
  constructor(
    readonly props: {
      machine: number;
      faultCode: number;
      selectedRecommendations?: SelectedRecommendation[];
      additionalNote?: string;
      images?: (string | ArrayBuffer[])[];
    }
  ) {}
}

export class SolveProblemSuccessful {
  static readonly type = '[Solve] Solve Problem Successful';
}

export class CreateRecommendation {
  static readonly type = '[Troubleshooting] Create A Recommendation';
  constructor(
    readonly faultCode: number,
    readonly actionName: string,
    readonly images: string[]
  ) {}
}

export class UpdateRecommendation {
  static readonly type = '[Troubleshooting] Update A Recommendation';
  constructor(readonly recommendation: RecommendationDto) {}
}

export class ValidateRecommendation {
  static readonly type = '[Troubleshooting] Validate A Recommendation';
  constructor(readonly recommendation: RecommendationDto) {}
}

export class DeleteRecommendation {
  static readonly type = '[Troubleshooting] Delete A Recommendation';
  constructor(readonly recommendationID: number) {}
}

export class SetSelectedFault {
  static readonly type = '[Troubleshooting] Set Selected Fault Code';
  constructor(readonly faultCode: number) {}
}

export class ClearSelectedFault {
  static readonly type = '[Troubleshooting] Clear Selected Fault Code';
}
