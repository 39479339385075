import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RecommendationDto, RecommendationStatus } from '@app/shared/models';
import { TranslateService } from '@ngx-translate/core';
import { RecommendationAction } from '../recommendations-list';

@Component({
  selector: 'gea-hrt-recommendation-readonly-actions',
  styleUrl: 'readonly-actions.component.scss',
  templateUrl: './readonly-actions.component.html',
})
export class ReadonlyActionsComponent {
  Status = RecommendationStatus;

  @Input({ required: true }) recommendation!: RecommendationDto;

  @Output() actionClicked = new EventEmitter<RecommendationAction>();

  constructor(public readonly translate: TranslateService) {}
}
