import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColumnRendererComponent } from '@gea/digital-ui-lib';
import { Shutdown, Warning } from '@shared/models';

@Component({
  selector: 'gea-hrt-text-solved-render',
  standalone: true,
  imports: [CommonModule],
  template: ` <span>{{ '(' + rowData?.countSolved + ')' }}</span> `,
  styles: ``,
})
export class TextSolvedRenderComponent implements ColumnRendererComponent<Warning | Shutdown> {
  rowData?: Warning | Shutdown;
}
