import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'gea-hrt-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoBoxComponent {
  @Input()
  boxTitle = '';

  @Input()
  tooltipHeading = '';

  @Input()
  tooltip = '';

  @Input()
  withBorder? = false;
}
