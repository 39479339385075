import { Pipe, PipeTransform } from '@angular/core';
import { SelectOption } from '@gea/digital-ui-lib';

@Pipe({
  name: 'toSelectOptions',
})
export class ToSelectOptionsPipe implements PipeTransform {
  transform(value: number[]): SelectOption[] {
    if (!value) {
      return value;
    }

    return value.map((value) => ({ value }));
  }
}
