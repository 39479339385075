/**
 * asks for a distinct list of fault codes,
 * that are available for a given date range and status
 */
export class FetchFaultCodes {
  static readonly type = '[FaultAnalysis] Fetch Fault Codes';
  constructor(
    public readonly machines: number[],
    public readonly dateRange?: string,
    public readonly status?: string[]
  ) {}
}

export class FetchFaultCodesForMachine {
  static readonly type = '[Faults] Fetch Fault Codes for Machine';
  constructor(public readonly machine: number) {}
}

/**
 * asks for a distinct list of faults,
 * that are available for a given machines, date range, status and faultCodes
 */
export class FetchFaultAnalysis {
  static readonly type = '[FaultAnalysis] Fetch Faults';
  constructor(
    public readonly machines: number[],
    public readonly dateRange: string,
    public readonly status: string[],
    public readonly faultCodeGroups: number[]
  ) {}
}

/**
 * clear the list of faults
 * e.g. prior to fetching new ones
 */
export class ClearFaults {
  static readonly type = '[FaultAnalysis] Clear Faults';
}

export class ResetFaultAnalysis {
  static readonly type = '[FaultAnalysis] Clear Shutdowns and Warnings';
}

/**
 * sets an array of faultCodes as selected.
 * Subset of the whole list of faultCodes.
 */
export class SetSelectedFaultCodes {
  static readonly type = '[FaultAnalysis] Set Selected Fault Codes';
  constructor(public readonly faultCodes: number[]) {}
}

/**
 * sets an array of faultCodeGroups as selected.
 * Subset of the whole list of faultCodes.
 */
export class SetSelectedFaultCodeGroups {
  static readonly type = '[FaultAnalysis] Set Selected Fault Code Groups';
  constructor(public readonly faultCodeGroups: number[]) {}
}

export class SortShutdowns {
  static readonly type = '[CustomerOverview] Sort Shutdowns';
  constructor(
    public readonly columName: string,
    public readonly sortOrder: string
  ) {}
}

export class SortWarnings {
  static readonly type = '[CustomerOverview] Sort Warnings';
  constructor(
    public readonly columName: string,
    public readonly sortOrder: string
  ) {}
}
