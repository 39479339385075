import { FilterTableSettings } from '@gea/digital-ui-lib';
import { Shutdown, Warning } from '@shared/models';

export function sortTableData(tableFilter: FilterTableSettings, data: Warning[] | Shutdown[]) {
  const keys = Object.keys(tableFilter.columns);
  const columns = keys.map((key) => {
    return { key, sortDirection: tableFilter.columns[key]?.sort };
  });

  columns.forEach((column) => {
    if (!column) return;
    const key = column.key;
    data = [...data].sort((a, b) => {
      if (!column.sortDirection) return 0;

      if (typeof a[key] === 'string') return sortStrings(a[key] as string, b[key] as string, column.sortDirection);

      if (typeof a[key] === 'number') return sortNumbers(a[key] as number, b[key] as number, column.sortDirection);

      return 0;
    });
  });
  return data;
}

function sortStrings(a: string, b: string, sortDirection: string) {
  if (sortDirection === 'asc') return a.localeCompare(b);
  else return b.localeCompare(a);
}

function sortNumbers(a: number, b: number, sortDirection: string) {
  if (sortDirection === 'asc') return a - b;
  else return b - a;
}
