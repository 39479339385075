<p-accordionTab>
  <ng-template pTemplate="header">
    <gea-hrt-health-component-header [component]="component" />
  </ng-template>
  <div class="system-health-machine-component">
    <div class="health-cards">
      <gea-hrt-system-health-check
        *ngFor="let data of sortedHealthChecks"
        [organizationId]="organizationId"
        [machine]="machineId"
        [data]="data" />
    </div>
  </div>
</p-accordionTab>
