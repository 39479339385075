import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

const HEALTHY = '#4CAF50';
const WARNINGS = '#FFA000';
const colors = [HEALTHY, WARNINGS];

type Legend = { label: string; value: number; hint: string; color?: string };

@Component({
  selector: 'gea-hrt-machine-status',
  templateUrl: './machine-status.component.html',
  styleUrl: './machine-status.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MachineStatusComponent implements OnInit {
  @Input() data!: {
    healthy: number;
    warnings: number;
    critical: number;
  };

  chartData: Legend[] = [];
  legends: Legend[] = [];

  constructor(public readonly translateService: TranslateService) {}

  ngOnInit(): void {
    const { healthy, warnings, critical } = this.data;

    // Combine warnings and critical values into one for warnings
    const combinedWarnings = warnings + critical;

    this.chartData = [
      { label: 'Healthy', value: healthy, hint: 'SYSTEM_HEALTH.MACHINE_STATUS.HEALTHY' },
      { label: 'Warnings', value: combinedWarnings, hint: 'SYSTEM_HEALTH.MACHINE_STATUS.WARNINGS' },
    ];

    this.generateLegends();
  }

  generateLegends(): void {
    this.legends = this.chartData.map((item, index) => ({
      label: item.label,
      color: colors[index % colors.length],
      value: item.value,
      hint: item.hint,
    }));
  }

  get backgroundStyle(): string {
    const gradientStops = this.chartData.map((item, index) => {
      const startValue = this.chartData.slice(0, index).reduce((acc, curr) => acc + curr.value, 0);
      return `${colors[index % colors.length]} ${startValue}% ${startValue + item.value}%`;
    });

    return `conic-gradient(${gradientStops.join(', ')})`;
  }

  getLabelStyle(value: number): object {
    const angles = this.chartData.map((v) => (v.value / 100) * 360);
    const cumulativeAngle =
      angles
        .slice(
          0,
          this.chartData.findIndex((v) => v.value === value)
        )
        .reduce((a, b) => a + b, 0) +
      angles[this.chartData.findIndex((v) => v.value === value)] / 2;

    const radius = 50; // Adjust as needed
    const x = radius * Math.cos((cumulativeAngle - 90) * (Math.PI / 180));
    const y = radius * Math.sin((cumulativeAngle - 90) * (Math.PI / 180));

    return {
      transform: `translate(${x}px, ${y}px)`,
      textAlign: 'center',
    };
  }

  trackByLabel(_: number, item: { label: string }): string {
    return item.label;
  }
}
