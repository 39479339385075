import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SystemHealthCheck } from '@shared/models';
import { HealthCheckDialogComponent } from '../health-check-dialog';

@Component({
  selector: 'gea-hrt-system-health-check',
  templateUrl: './health-check.component.html',
  styleUrls: ['./health-check.component.scss'],
})
export class HealthCheckComponent {
  @Input({ required: true }) data!: SystemHealthCheck;
  @Input({ required: true }) organizationId!: number;
  @Input({ required: true }) machine!: number;

  constructor(public dialog: MatDialog) {}

  computedClass() {
    const className = {
      '-1': 'info',
      0: 'healthy',
      1: 'warning',
      2: 'error',
    }[this.data.status];
    return `${className}`;
  }

  get meta(): [string, number][] {
    // @ts-expect-error Type '[string, string | number][]' is not assignable to type '[string, number][]'.
    return Object.entries(this.data.healthStatusMetadata).slice(0, 3);
  }

  openDetailsPopUp() {
    this.dialog.open(HealthCheckDialogComponent, {
      data: this.data,
      width: '60vw',
      height: '60vh',
      autoFocus: true,
    });
  }
}
