import { Component, OnInit } from '@angular/core';
import { ResolvedFault } from '@app/shared/models';
import { ColumnRendererComponent } from '@gea/digital-ui-lib';

@Component({
  selector: 'gea-hrt-solved-fault-date-renderer',
  template: `
    @if (date) {
      <gea-hrt-update-time [date]="date" />
    }
  `,
})
export class DateRendererComponent implements OnInit, ColumnRendererComponent<ResolvedFault> {
  rowData?: ResolvedFault;
  config!: { columnKey: 'solvedOn' };
  date?: string;

  ngOnInit(): void {
    if (this.rowData) {
      this.date = this.rowData[this.config.columnKey];
    }
  }
}
