import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HrtApiService } from './hrt-api.service';
import { MachineAndFaultDto } from '@shared/models';

@Injectable({
  providedIn: 'root',
})
export class MachinesApiService {
  machinesUrl = 'machines';

  constructor(readonly api: HrtApiService) {}

  fetchMachines(): Observable<MachineAndFaultDto[]> {
    return this.api.getForOrganization<MachineAndFaultDto[]>(this.machinesUrl);
  }
}
