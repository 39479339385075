import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gea-hrt-update-time',
  template: `<span [title]="date | date: 'd/MM/YYYY - HH:mm zzzz'">
    {{ date | date: GetFormat() }}
  </span> `,
})
export class UpdateTimeComponent {
  constructor(readonly translateService: TranslateService) {
    this.currentLanguage = translateService.currentLang;
  }
  currentLanguage = 'en-US';
  @Input({ required: true }) date!: string | Date;

  GetFormat(): string {
    switch (this.currentLanguage) {
      case 'de-DE':
        return 'dd.MM.';
      default:
      case 'en-US':
        return 'dd/MM';
    }
  }
}
