import { Component, OnInit } from '@angular/core';
import { ResolvedFault } from '@app/shared/models';
import { ColumnRendererComponent } from '@gea/digital-ui-lib';
import { TranslateEnumService } from '@shared/services';

@Component({
  selector: 'gea-hrt-resolved-fault-status-renderer',
  template: `
    <div [class]="rowData.status">
      {{ text }}
    </div>
  `,
  styles: `
    div {
      text-align: center;
      color: white;
      padding: 0.4rem;
      background-color: var(--geaui-light-gray);
      &.open {
        color: var(--geaui-yellow);
      }
      &.useful {
        color: var(--geaui-light-green);
      }
      &.discarded {
        color: var(--geaui-light-red);
      }
    }
  `,
})
export class ResolvedFaultStatusRendererComponent implements ColumnRendererComponent<ResolvedFault>, OnInit {
  rowData!: ResolvedFault;
  text = '';
  constructor(readonly enumTranslation: TranslateEnumService) {}

  ngOnInit() {
    this.text = this.enumTranslation.GetTranslationSingular(this.rowData.status);
  }
}
