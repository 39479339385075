<div class="pie-chart-wrapper">
  <div class="legend">
    <h2>{{ 'SYSTEM_HEALTH.MACHINE_STATUS.TITLE' | translate }}</h2>
    <div class="legend-item" *ngFor="let legend of legends; trackBy: trackByLabel" [style]="{ color: legend.color }">
      <ng-container *ngIf="legend.value">
        <span class="legend-color-box" [ngStyle]="{ 'background-color': legend.color }"></span>
        <span class="legend-value">{{ legend.value }}%</span> &nbsp;-&nbsp;
        <span class="legend-label"> {{ legend.hint | translate }}</span>
      </ng-container>
    </div>
  </div>
  <div class="pie-chart-container">
    <div class="pie-chart" [ngStyle]="{ background: backgroundStyle }">
      <ng-container *ngFor="let item of chartData; trackBy: trackByLabel">
        <div *ngIf="item.value" class="slice-label" [ngStyle]="getLabelStyle(item.value)">{{ item.value }}%</div>
      </ng-container>
    </div>
  </div>
</div>
