import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { RecommendationDto } from '@app/shared/models';

@Component({
  selector: 'gea-hrt-delete-recommendation-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss'],
})
export class DeleteRecommendationDialogComponent {
  constructor(public dialogRef: MatDialogRef<DeleteRecommendationDialogComponent>) {}

  recommendation?: RecommendationDto;

  commit() {
    this.dialogRef.close(this.recommendation);
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
