import { ResolvedFault } from '@shared/models';
import { Component, inject, OnInit } from '@angular/core';
import { ColumnRendererComponent, TableServiceV2 } from '@gea/digital-ui-lib';
import { VIEW_SOLVED_FAULT_IMAGES } from '@tab-container/fault-recommendations';

@Component({
  selector: 'gea-hrt-solved-fault-images-renderer',
  template: `
    @if (hasImages) {
      <geaui-icon-v2 file="image" (click)="onClick()" />
    }
  `,
  styles: ``,
})
export class SolvedFaultImagesRendererComponent implements OnInit, ColumnRendererComponent<ResolvedFault> {
  rowData?: ResolvedFault;
  hasImages = false;
  tableService = inject(TableServiceV2);
  tableId = 'ert';

  ngOnInit(): void {
    if (this.rowData) {
      this.hasImages = (this.rowData['images']?.length ?? 0) > 0;
    }
  }

  onClick() {
    this.tableService.publishAction(this.tableId, VIEW_SOLVED_FAULT_IMAGES, this.rowData);
  }
}
