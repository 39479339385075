export interface TroubleshootingDto {
  recommendationText: string;
  recommendations: RecommendationDto[];
  status: RecommendationFaultStatus;
}

export type RecommendationReviewStatus = 'open' | 'useful' | 'discarded';
export interface RecommendationDto {
  id: number;
  actionName: string;
  status: RecommendationStatus;
  images: string[];
  reviewStatus?: RecommendationReviewStatus;
  usageCount?: number;
  type: number;
  validationCount: number;
  canValidate: boolean;
}

export interface RecommendationOrderDto {
  id: number;
  order: number;
  images: string[];
}

export interface ProblemResolveDto {
  additionalNote?: string;
  selectedRecommendations: SelectedRecommendation[];
  images?: (string | ArrayBuffer[])[];
}

export interface SelectedRecommendation {
  id: number;
  title: string;
}

export interface RecommendationsStateModel {
  recommendationText: string;
  recommendations: RecommendationDto[];
  approvedRecommendations: RecommendationDto[];
  status?: RecommendationFaultStatus;
  problemSolvedSuccess: boolean;
  selected?: number;
}

export enum RecommendationStatus {
  PENDING = 0,
  SKIPPED = 1,
  DONE = 2,
}

export enum RecommendationFaultStatus {
  PENDING = 0,
  SOLVED = 1,
}
