<span class="title-container" [ngSwitch]="type" [ngClass]="{ disabled }">
  <h1 class="title" *ngSwitchDefault [ngClass]="{ 'title-blurred': blur }">
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </h1>
  <h2 class="title" *ngSwitchCase="'sub'" [ngClass]="{ 'title-blurred': blur }">
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </h2>
  <h3 class="title" *ngSwitchCase="'minor'" [ngClass]="{ 'title-blurred': blur }">
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </h3>
  <h4 class="title" *ngSwitchCase="'tiny'" [ngClass]="{ 'title-blurred': blur }">
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </h4>
</span>

<ng-template #content>
  <ng-container *ngIf="text">{{ text }}</ng-container>
  <ng-container class="content" *ngIf="!text"><ng-content></ng-content></ng-container>
</ng-template>
