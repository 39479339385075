<gea-hrt-info-box [boxTitle]="title" [tooltip]="tooltip" [withBorder]="true">
  <p class="small-font">
    {{ 'FAULT_ANALYSIS.DATA_EXTRACTED_ON' | translate }}:
    <gea-hrt-update-time [date]="currentDate" />
  </p>
  <div class="table-container-section">
    <ng-container *ngIf="showTable">
      <ng-container *ngIf="data.length; else NoData">
        <geaui-table-v2
          class="table"
          [tableId]="TABLE_ID"
          [columnDefinitions]="columnDefinitions"
          [sortable]="true"
          [data]="data" />
      </ng-container>
      <ng-template #NoData>
        <gea-hrt-empty-data-placeholder text="{{ placeholder | titlecase }}" />
      </ng-template>
    </ng-container>
  </div>
</gea-hrt-info-box>
