import { Action, Select, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ErrorHandlerV2Service, UserModel, UserState } from '@gea/digital-ui-lib';

import { MembershipModel, MembershipStateModel } from '@shared/models';
import { FetchMemberships } from '@shared/state/memberships/memberships.action';
import { catchError, finalize, first, map, Observable, of, switchMap, tap } from 'rxjs';
import { FetchOrganizations } from '@shared/state';
import { UserService } from '@shared/services';
import { User } from '@shared/models/user.model';

@State<MembershipStateModel>({
  name: 'memberships',
  defaults: {
    memberships: [],
  },
})
@Injectable()
export class MembershipsState {
  @Select(UserState.user) public user$!: Observable<UserModel>;

  constructor(
    readonly usersApiService: UserService,
    readonly errorHandler: ErrorHandlerV2Service
  ) {}

  @Selector()
  static memberships(state: MembershipStateModel): MembershipModel[] {
    return state.memberships;
  }

  @Action(FetchMemberships)
  fetchMemberships({ dispatch, patchState }: StateContext<MembershipStateModel>) {
    this.user$
      .pipe(
        map((user) => user.userId ?? ''),
        switchMap((userId: string) => this.usersApiService.getUser(userId).pipe(map((user: User) => user.memberships ?? []))),
        tap((memberships: MembershipModel[]) => patchState({ memberships })),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of([]);
        }),
        finalize(() => dispatch(new FetchOrganizations())),
        first()
      )
      .subscribe();
  }
}
