import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColumnRendererComponent } from '@gea/digital-ui-lib';
import { Organization } from '@shared/models';

@Component({
  selector: 'gea-hrt-text-organization-name-render',
  standalone: true,
  imports: [CommonModule],
  template: ` <h5 [title]="rowData?.name">{{ rowData?.name }}</h5> `,
  styles: `
    h5 {
      font-size: 16px;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `,
})
export class TextOrganizationNameRenderComponent implements ColumnRendererComponent<Organization> {
  rowData?: Organization;
}
