<div class="progressbar-wrapper">
  <span
    class="progressbar-count"
    [style.backgroundColor]="config.countColor"
    [style.height.px]="config.barHeight"
    [style.width.%]="getCountWidth()"
    style="position: inherit">
  </span>
  <span
    class="progressbar-total-count"
    [style.backgroundColor]="config.totalCountColor"
    [style.height.px]="config.barHeight"
    [style.width.%]="getTotalCountWidth()"
    style="position: inherit">
  </span>
</div>
