<gea-hrt-info-box
  boxTitle="{{ 'FAULT_RECOMMENDATIONS.SOLVED_FAULTS.TITLE' | translate }}"
  tooltip="{{ 'FAULT_RECOMMENDATIONS.SOLVED_FAULTS.TOOLTIP' | translate }}"
  [withBorder]="true">
  <div class="table-container-section">
    <geaui-table-v2
      class="table"
      [tableId]="TABLE_ID"
      [columnDefinitions]="columnDefinitions"
      [sortable]="true"
      [data]="parsedData ?? []" />
  </div>
</gea-hrt-info-box>
