<ng-container>
  <geaui-button-v2
    *ngIf="recommendation.validationCount < 2"
    [disabled]="!recommendation.canValidate"
    type="secondary"
    class="validate-button"
    data-testid="btn-validate"
    (click)="validateRecommendation.emit(recommendation)">
    <span>
      {{ 'TROUBLESHOOTING.RECOMMENDATIONS.BUTTON_VALIDATE' | translate }}
    </span>
  </geaui-button-v2>

  <geaui-button-v2
    *ngIf="recommendation.reviewStatus === 'useful'"
    type="secondary"
    data-testid="btn-save"
    (click)="setRecommendationUseful.emit(recommendation)">
    <geaui-icon-v2 file="save" />
    <span>
      {{ 'TROUBLESHOOTING.RECOMMENDATIONS.BUTTON_SAVE' | translate }}
    </span>
  </geaui-button-v2>

  <geaui-button-v2 type="secondary" data-testid="btn-edit" (click)="openEditRecommendationDialog.emit(recommendation)">
    <geaui-icon-v2 file="pencil" />
    <span>
      {{ 'TROUBLESHOOTING.RECOMMENDATIONS.BUTTON_EDIT' | translate }}
    </span>
  </geaui-button-v2>

  <geaui-button-v2
    type="secondary"
    data-testid="btn-delete"
    class="delete-button"
    (click)="openDeleteRecommendationDialog.emit(recommendation)">
    <geaui-icon-v2 file="trash-can" />
    <span class="label">
      {{ 'TROUBLESHOOTING.RECOMMENDATIONS.BUTTON_DELETE' | translate }}
    </span>
  </geaui-button-v2>
</ng-container>
