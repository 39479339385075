import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MeasurementSerieDto } from '@shared/models';
import { cssVar } from '@shared/helpers';
import { ChartData, ChartSerie } from './data-chart';
import { TranslateService } from '@ngx-translate/core';

type MeasurementsConfig = Record<string, { color: string; yAxisPosition: 'left' | 'right' }>;

const measurementsConfig: MeasurementsConfig = {
  'Pending Fault': { color: cssVar('--geaui-brighter-red'), yAxisPosition: 'left' },
  pressure: { color: '#2F80ED', yAxisPosition: 'left' },
  temperature: { color: 'red', yAxisPosition: 'left' },
  motor_speed: { color: 'green', yAxisPosition: 'right' },
  motor_current: { color: 'blue', yAxisPosition: 'right' },
  saturated_suction_temperature: { color: '#fd7e14', yAxisPosition: 'right' },
  saturated_discharge_temperature: { color: '#6f42c1', yAxisPosition: 'right' },
};

@Component({
  selector: 'gea-hrt-related-operating-data',
  templateUrl: './related-operating-data.component.html',
  styleUrls: ['./related-operating-data.component.scss'],
})
export class RelatedOperatingDataComponent {
  switches: [string, string, boolean][] = [];
  groupedChartData: [string, ChartData][] = [];
  data: MeasurementSerieDto[] = [];
  defaultSelection = ['temperature', 'pressure'];

  loading = false;

  constructor(readonly translation: TranslateService) {}
  @Input() set measurements(measurements: MeasurementSerieDto[] | null) {
    if (!measurements) return;

    this.loading = false;
    this.data = measurements.map((temp) => ({
      ...temp,
      measurementType:
        this.translation.instant('SIGNAL_MEASUREMENT_TYPE.' + temp.measurementType.toLowerCase()) || temp.measurementType,
      measurementKey: temp.measurementType.toLowerCase(),
    }));

    this.updateSwitchesAndChart(this.defaultSelection);
  }

  @Output() setDateRange = new EventEmitter<number | null>();

  private updateSwitchesAndChart(defaultSelection: string[]) {
    this.switches = this.filterUniqueEntries(
      this.data.map((curr) => [curr.measurementKey, curr.measurementType, defaultSelection.includes(curr.measurementKey)])
    );
    this.generateChart(defaultSelection);
  }

  generateChart(types: string[]) {
    const filteredMeasurements = this.data.filter(({ measurementKey }) => types.includes(measurementKey));
    const temp: Record<string, MeasurementSerieDto[]> = {};

    filteredMeasurements.forEach((measurement) => {
      const key = measurement.component;
      if (!temp[key]) {
        temp[key] = [];
      }
      temp[key].push(measurement);
    });

    this.groupedChartData = Object.entries(temp).map(([component, measurements]) => [
      component,
      { series: this.generateSeries(measurements) },
    ]);
  }

  private generateSeries(data: MeasurementSerieDto[]): ChartSerie[] {
    let leftOffset = 0;
    let rightOffset = 0;

    return data.map(({ measurementType, dataPoints, measurementUnit, measurementKey }) => {
      const { color, yAxisPosition } = measurementsConfig[measurementKey] || { color: 'black', yAxisPosition: 'left' };
      const yAxisOffset = yAxisPosition === 'left' ? leftOffset : rightOffset;

      if (yAxisPosition === 'left') {
        leftOffset += 50;
      } else {
        rightOffset += 50;
      }

      return {
        name: measurementType,
        color,
        data: dataPoints.map(({ timestamp, value }) => [timestamp, Number(value)]),
        legendLabel: measurementType,
        yAxisName: measurementUnit,
        yAxisPosition,
        yAxisOffset,
        xValues: dataPoints.map(({ timestamp }) => timestamp),
      };
    });
  }

  private filterUniqueEntries(data: [string, string, boolean][]): [string, string, boolean][] {
    const seen = new Set<string>();
    return data.filter(([name]) => {
      if (seen.has(name)) {
        return false;
      }
      seen.add(name);
      return true;
    });
  }

  dateRangeChanged(dateRange: number | null) {
    this.setDateRange.emit(dateRange);
  }
}
