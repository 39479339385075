/**
 * asks for a list of available customers
 */
import { Organization } from '@shared/models';

export class FetchOrganizations {
  static readonly type = '[CustomerOverview] Fetch Organizations';
}

/**
 * sets a single customer as selected.
 */
export class SetSelectedOrganization {
  static readonly type = '[CustomerOverview] Set Selected Organization';
  constructor(public readonly organization: Organization | null) {}
}

export class SortOrganizations {
  static readonly type = '[CustomerOverview] Sort Organizations';
  constructor(
    public readonly columName: string,
    public readonly sortOrder: string,
    public readonly organizationId?: number
  ) {}
}

export class FilterOrganizations {
  static readonly type = '[CustomerOverview] Filter Organizations';
  constructor(public readonly filterString: string) {}
}
