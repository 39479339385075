import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColumnRendererComponent, ComplexDialogV2Service } from '@gea/digital-ui-lib';
import { Organization } from '@shared/models';
import { ActionCellComponent } from '@customer-overview/table-config/renderer/fault-cards-renderer/action-cell';
import { FaultCardComponent } from '@customer-overview/table-config/renderer/fault-cards-renderer/fault-card/fault-card.component';

@Component({
  selector: 'gea-hrt-fault-cards',
  standalone: true,
  imports: [CommonModule, FaultCardComponent, ActionCellComponent],
  templateUrl: './fault-cards-render.component.html',
  styleUrl: './fault-cards-render.component.scss',
})
export class FaultCardsRenderComponent implements ColumnRendererComponent<Organization> {
  rowData?: Organization;
  constructor(readonly dialogService: ComplexDialogV2Service) {}

  onCloseDialog() {
    this.dialogService.close();
  }
}
