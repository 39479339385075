<mat-spinner *ngIf="loading; else content" class="spinner" [diameter]="40" />
<ng-template #content>
  <ng-container *ngIf="recommendations.length; else noSolution">
    <gea-hrt-heading class="recommendation-text" type="sub">
      {{ recommendationText }}
    </gea-hrt-heading>
    <p>{{ 'TROUBLESHOOTING.RECOMMENDATIONS.STEPS_MESSAGE' | translate }}</p>
    <gea-hrt-recommendations-list
      [list]="recommendations"
      (actionClicked)="handleRecommendationAction.emit($event)"
      [isEditable]="false" />
    <gea-hrt-troubleshooting-problem-solve [list]="recommendations" (emitProblemSolved)="emitProblemSolved.emit($event)" />
  </ng-container>
  <ng-template #noSolution>
    <gea-hrt-hint>{{ 'TROUBLESHOOTING.RECOMMENDATIONS.NO_DATA' | translate }}</gea-hrt-hint>
  </ng-template>

  <ng-container *ngIf="recommendations.length === 0">
    <ng-template #noSolution>
      <gea-hrt-hint>{{ 'TROUBLESHOOTING.RECOMMENDATIONS.NO_DATA' | translate }}</gea-hrt-hint>
    </ng-template>
    <gea-hrt-troubleshooting-problem-solve
      id="problemSolvedButton"
      class="problem-solved-button"
      [showText]="false"
      [list]="recommendations"
      (emitProblemSolved)="emitProblemSolved.emit($event)">
    </gea-hrt-troubleshooting-problem-solve>
  </ng-container>
</ng-template>
