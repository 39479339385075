import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { SelectOption } from '@gea/digital-ui-lib';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gea-hrt-ts-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangeComponent implements OnInit, OnDestroy {
  /**
   * the default value in hours (see options, for available values)
   */
  @Input() defaultHours = 24;

  selectOption: SelectOption<number> = { name: '', value: 24 };
  /**
   * publishes the dateRange in hours
   */
  @Output() hoursChanged: EventEmitter<number | null> = new EventEmitter<number | null>();

  public options: SelectOption<number>[] = [
    { name: '', value: 1 },
    { name: '', value: 24 },
    { name: '', value: 24 * 7 },
  ];

  public formGroup: FormGroup;

  private readonly optionTranslations = [
    'TROUBLESHOOTING.RELATED_OPERATING_DATA.DATE_RANGE.LAST_HOUR',
    'TROUBLESHOOTING.RELATED_OPERATING_DATA.DATE_RANGE.LAST_24HOURS',
    'TROUBLESHOOTING.RELATED_OPERATING_DATA.DATE_RANGE.LAST_7DAYS',
  ];
  private readonly subscriptions: Subscription[] = [];

  constructor(
    readonly formBuilder: FormBuilder,
    readonly translate: TranslateService
  ) {
    const dateRange = new FormControl<number>(this.defaultHours);
    this.formGroup = this.formBuilder.group({ dateRange });
  }

  ngOnInit() {
    this.subscriptions.push(this.translate.onLangChange.subscribe(() => this.translateOptions()));
    this.translateOptions();
    this.selectOption = this.options.find((option) => option.value === this.defaultHours) ?? this.options[1];
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  translateOptions() {
    this.options = this.options.map((option, i) => {
      option.name = this.translate.instant(this.optionTranslations[i]) as string;
      return { ...option };
    });
  }

  onSelectedTimeRangeChanged(event: { value: SelectOption<number> } | SelectOption<number>) {
    if (this.isSelectOption(event)) {
      this.hoursChanged.emit(event.value);
    } else {
      this.hoursChanged.emit(event.value.value);
    }
  }

  private isSelectOption(obj: unknown): obj is SelectOption<number> {
    return (obj as SelectOption<number>).name !== undefined && (obj as SelectOption<number>).value !== undefined;
  }
}
