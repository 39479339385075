<div class="customer-overview-container">
  <mat-spinner *ngIf="loading; else tableContent" class="spinner" [diameter]="40" />
  <ng-template #tableContent>
    <div class="flex-direction-column">
      <div class="search-container">
        <geaui-search-bar-v2
          class="search-bar"
          [placeholder]="'CUSTOMER_OVERVIEW.SEARCH_TERM' | translate"
          (valueChanged)="onSearchInputChange($event)"></geaui-search-bar-v2>
        <span class="icon">
          <geaui-icon-v2
            pTooltip="{{ 'CUSTOMER_OVERVIEW.INFO.TEXT' | translate }}"
            [color]="'black'"
            tooltipPosition="left"
            [file]="'24px_c-info'">
          </geaui-icon-v2>
        </span>
      </div>
      <div class="table">
        <geaui-table-v2
          *ngIf="organizations?.length !== 0; else noData"
          [tableId]="TABLE_ID"
          [columnDefinitions]="columns"
          [data]="organizations ?? []"
          [noDataText]="'CUSTOMER_OVERVIEW.NO_DATA' | translate"
          [sortable]="true">
        </geaui-table-v2>
      </div>
    </div>
    <ng-template #noData>
      <gea-hrt-empty-data-placeholder [text]="'UI-LIB.HEADER.ORGA' | translate" />
    </ng-template>
  </ng-template>
</div>
