<div class="fault-recommendations-delete-dialog-container">
  <div mat-dialog-title class="delete-dialog-title">
    <gea-hrt-heading class="dialog-title" type="sub">{{
      'TAB_CONTAINER.FAULT_RECOMMENDATIONS.DELETE_DIALOG.TITLE' | translate
    }}</gea-hrt-heading>
    <gea-hrt-close-dialog-button (closeDialog)="closeDialog()" />
  </div>
  <mat-dialog-content>
    <h3>{{ 'TAB_CONTAINER.FAULT_RECOMMENDATIONS.DELETE_DIALOG.PROMPT' | translate }}?</h3>
    <div class="buttons">
      <geaui-button-v2 [type]="'secondary'" data-testid="button-cancel" class="cancel" (click)="closeDialog()">
        {{ 'UI-LIB.GENERAL.CANCEL' | translate }}
      </geaui-button-v2>
      <geaui-button-v2 [type]="'secondary'" data-testid="button-delete" class="delete" (click)="commit()">
        {{ 'UI-LIB.GENERAL.DELETE' | translate }}
      </geaui-button-v2>
    </div>
  </mat-dialog-content>
</div>
