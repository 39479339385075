import 'dayjs/locale/de';
import dayjs from 'dayjs';

const TEMPLATE = 'YYYY-MM-DDThh:mm:ss';
const DEFAULT_FORMAT = 'DD.MM.YYYY';
const FORMAT_MAP: { [locale: string]: string } = {
  'en-US': 'MM/DD/YYYY',
  'de-DE': 'DD.MM.YYYY',
};

export class DateHelper {
  static daysBack(minuend?: number): string {
    if (minuend === undefined) {
      return '';
    }
    return dayjs().subtract(minuend, 'days').format(TEMPLATE);
  }

  static hoursBack(minuend?: number): string {
    if (minuend === undefined) {
      return '';
    }
    return dayjs().subtract(minuend, 'hours').format(TEMPLATE);
  }

  static localize(date: Date, locale: string): string {
    return dayjs(date).format(FORMAT_MAP[locale] || DEFAULT_FORMAT);
  }
}
