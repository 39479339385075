import { HrtApiService } from './hrt-api.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SystemHealthModel } from '@shared/models';
import { DateHelper } from '../helpers';

const DEFAULT_DATE_RANGE = DateHelper.daysBack(28);

@Injectable({
  providedIn: 'root',
})
export class SystemHealthApiService {
  constructor(readonly api: HrtApiService) {}

  getHealthCheck(machine: number, dateRange = DEFAULT_DATE_RANGE): Observable<SystemHealthModel> {
    return this.api.getForOrganization<SystemHealthModel>(`machines/${machine}/health-checks`, {
      params: { machine, dateRange },
    });
  }
}
