<div class="troubleshooting-filters" [ngClass]="layout.screenClasses | async">
  <div class="flex">
    <div [ngClass]="simpleFormFieldClasses">
      <ng-container *ngIf="machineOptions.length">
        <geaui-select-v2
          id="machine"
          [label]="'TROUBLESHOOTING.MACHINE' | translate"
          [group]="formGroup"
          [options]="machineOptions"
          [selected]="{ value: selectedMachine }"
          (selectChanged)="onMachineChange($event.value.value)" />
      </ng-container>
    </div>
    <div [ngClass]="simpleFormFieldClasses">
      <geaui-select-v2
        id="faultCode"
        [label]="'TROUBLESHOOTING.FAULT_NAME' | translate"
        [group]="formGroup"
        [disabled]="!selectedMachine"
        [options]="faultOptions"
        [selected]="{ value: selectedFault }"
        (selectChanged)="onFaultCodeChange($event.value.value)" />
    </div>
  </div>
</div>
